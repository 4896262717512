import React from "react"

import { classNames } from "../../utils/helpers"

export interface BannerProps {
  color: string
  children: string
}

function Banner({ color, children }: BannerProps) {
  return (
    <div
      className={classNames(
        color,
        "w-full py-2 px-8 text-center text-sm font-medium text-sand"
      )}
    >
      {children}
    </div>
  )
}

export default Banner
