import { Link } from 'gatsby';
import React from 'react';

import { classNames } from '../../utils/helpers';

interface ButtonProps {
  link?: string
  variant: "link" | "modal" | "external"
  color: "pink" | "purple" | "sand" | "sandDark" | "lightPurple"
  rounded?: "none" | "small" | "normal"
  type: "primary" | "secondary"
  children: string | JSX.Element
  modal?: Function
  fullWidth?: boolean
}

function Button({
  link,
  variant,
  color,
  rounded,
  type,
  children,
  modal,
  fullWidth = false,
}: ButtonProps) {
  let baseStyles = "text-sm font-medium text-center"
  let colorStyles = ""
  let roundedStyles = ""

  switch (type) {
    case "primary":
      baseStyles += " px-7 py-3"
      break
    case "secondary":
      baseStyles += " px-7 py-3"
      break
    default:
      baseStyles += " px-7 py-3"
      break
  }
  switch (color) {
    case "pink":
      colorStyles = "bg-pink text-sand"
      break
    case "purple":
      colorStyles = "bg-purple text-sand"
      break
    case "sand":
      colorStyles = "bg-sand text-purple"
      break
    case "sandDark":
      colorStyles = "bg-sand-800 text-purple"
      break
    case "lightPurple":
      colorStyles = "bg-lightPurple text-purple"
      break
    default:
      colorStyles = "bg-lightPurple text-purple"
      break
  }

  switch (rounded) {
    case "none":
      roundedStyles = "rounded-none"
      break
    case "small":
      roundedStyles = "rounded-sm"
      break
    case "normal":
      roundedStyles = "rounded-lg"
      break
    default:
      roundedStyles = "rounded-full"
  }

  if (fullWidth) {
    roundedStyles = "rounded-lg"
    baseStyles += " w-full"
  }

  if (variant === "modal") {
    return (
      <button
        type="button"
        onClick={modal}
        className={classNames(baseStyles, colorStyles, roundedStyles)}
      >
        {children}
      </button>
    )
  }

  if (variant === "link") {
    return (
      <Link to={link}>
        <button
          type="button"
          className={classNames(baseStyles, colorStyles, roundedStyles)}
        >
          {children}
        </button>
      </Link>
    )
  }

  if (variant === "external") {
    return (
      <a href={link}>
        <button
          type="button"
          className={classNames(baseStyles, colorStyles, roundedStyles)}
        >
          {children}
        </button>
      </a>
    )
  }
  return (
    <Link to={link}>
      <button
        type="button"
        className={classNames(baseStyles, colorStyles, roundedStyles)}
      >
        {children}
      </button>
    </Link>
  )
}

export default Button
