import React from "react"
import { FormattedMessage } from "react-intl"

const navMenu = [
  {
    id: 1,
    name: <FormattedMessage id="home" />,
    url: "/",
  },
  {
    id: 2,
    name: <FormattedMessage id="uitvaartorganisaties" />,
    url: "/uitvaartorganisaties",
  },
  {
    id: 3,
    name: <FormattedMessage id="nabestaanden" />,
    url: "/nabestaanden",
  },
  // {
  //   id: 4,
  //   name: <FormattedMessage id="executeurs" />,
  //   url: "/exucuteurs",
  // },
  {
    id: 5,
    name: <FormattedMessage id="contact" />,
    url: "/contact",
  },
  {
    id: 6,
    name: "Blog",
    url: "https://blog.nabestaandenloket.nl",
  },
]

// const resources = [
//   {
//     id: 1,
//     name: <FormattedMessage id="veelgesteldeVragen" />,
//     url: "/veelgestelde-vragen",
//   },
//   {
//     id: 2,
//     name: <FormattedMessage id="contact" />,
//     url: "/contact",
//   },
// ]

const overOns = [
  {
    id: 1,
    name: <FormattedMessage id="team" />,
    url: "/team",
  },
  {
    id: 2,
    name: <FormattedMessage id="vacancies" />,
    url: "https://www.linkedin.com/company/closure_services/jobs/",
  },
  {
    id: 3,
    name: <FormattedMessage id="contact" />,
    url: "/contact",
  },
]

const juridisch = [
  {
    id: 1,
    name: <FormattedMessage id="privacy" />,
    url: "/privacy",
  },
  {
    id: 2,
    name: <FormattedMessage id="cookies" />,
    url: "/cookies",
  },
  {
    id: 3,
    name: <FormattedMessage id="tos" />,
    url: "/algemene-voorwaarden",
  },
]

const navItems = [
  {
    id: 1,
    placeholder: <FormattedMessage id="oplossingen" />,
    navItems: [
      {
        id: 1,
        name: <FormattedMessage id="uitvaartorganisaties" />,
        url: "/uitvaartorganisaties",
      },
      {
        id: 2,
        name: <FormattedMessage id="nabestaanden" />,
        url: "/nabestaanden",
      },
      // {
      //   id: 3,
      //   name: <FormattedMessage id="executeurs" />,
      //   url: "/executeurs",
      // },
    ],
  },
  // {
  //   id: 2,
  //   placeholder: <FormattedMessage id="resources" />,
  //   navItems: resources,
  // },
  { id: 3, placeholder: <FormattedMessage id="aboutUs" />, navItems: overOns },
  { id: 4, placeholder: <FormattedMessage id="legal" />, navItems: juridisch },
]

const mobileNavItems = [
  {
    id: 1,
    placeholder: <FormattedMessage id="oplossingen" />,
    navItems: [
      {
        id: 1,
        name: <FormattedMessage id="uitvaartorganisaties" />,
        url: "/uitvaartorganisaties",
      },
      {
        id: 2,
        name: <FormattedMessage id="nabestaanden" />,
        url: "/nabestaanden",
      },
    ],
  },
  {
    id: 2,
    placeholder: <FormattedMessage id="legal" />,
    navItems: juridisch,
  },
  { id: 3, placeholder: <FormattedMessage id="aboutUs" />, navItems: overOns },
]

export { navItems, navMenu, mobileNavItems }
