import React from "react"

function HomeSEO() {
  return (
    <meta
      name="description"
      content="Nabestaandenloket is speciaal ontwikkeld voor de uitvaartsector om nabestaanden te helpen bij alle zaken die geregeld moeten worden na een overlijden. Denk hierbij aan het afwikkelen van abonnementen en zaken regelen rondom de erfenis."
    />
  )
}

export default HomeSEO
