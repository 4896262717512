import React, { Fragment, useState } from "react"
import { useIntl } from "react-intl"

import { Dialog, Transition } from "@headlessui/react"
import axios from "axios"

export interface WebinarModalProps {
  isOpen: boolean
  setIsOpen: Function
  nextWebinar?: string
}

export interface IFormDemo {
  name: string
  email: string
  organization: string
}

export default function WebinarModal({
  isOpen,
  setIsOpen,
  nextWebinar,
}: WebinarModalProps) {
  const intl = useIntl()
  const [formData, setFormData] = useState<IFormDemo>({
    name: "",
    email: "",
    organization: "",
  })

  const [error, setError] = useState({
    error: false,
    msg: "",
  })
  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const requestDemo = e => {
    e.preventDefault()
    if (!formData.name || !formData.organization || !formData.email) {
      setError(old => ({
        ...old,
        error: true,
        msg: "Please fill in all required fields",
      }))
      setSuccess(false)
      return false
    }
    return axios({
      url: `${process.env.GATSBY_API_BASE_URL}/webinar/register`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        if (res.status === 200) {
          setSuccess(true)
          setError(old => ({ ...old, error: false }))
        }
      })
      .catch(() => {
        setError({ error: true, msg: "Something went wrong" })
        setSuccess(false)
      })
  }
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-screen w-screen transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-lg transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle md:h-auto">
                <div className="h-full">
                  <div className="absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="text-plum-64 hover:text-plum rounded-md bg-white focus:outline-none"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="mx-auto flex max-w-md flex-col py-28 px-6 md:py-20">
                    <Dialog.Title
                      as="h3"
                      className="font-header text-2xl text-purple"
                    >
                      Aanmelden spreekuur Nabestaandenloket
                    </Dialog.Title>
                    {nextWebinar && (
                      <div className="mt-4 flex items-center space-x-4 rounded-lg border border-lightPurple-200 bg-lightPurple-50 p-4">
                        <div className="text-xl font-medium text-purple">
                          {nextWebinar}
                        </div>
                        <div className="text-purple">09:00-10:00</div>
                      </div>
                    )}
                    <form method="post" onSubmit={requestDemo}>
                      <div className="mt-8 flex flex-col justify-center space-y-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({
                            id: "namePlaceholder",
                          })}
                          onChange={handleChange}
                          value={formData.name}
                        />
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({
                            id: "emailPlaceholder",
                          })}
                          onChange={handleChange}
                          value={formData.email}
                        />
                        <input
                          type="text"
                          name="organization"
                          id="organization"
                          className="w-full rounded-md border border-gray-200 py-4 px-6 shadow-sm sm:text-sm"
                          placeholder={intl.formatMessage({
                            id: "companyPlaceholder",
                          })}
                          onChange={handleChange}
                          value={formData.organization}
                        />
                      </div>
                      <button
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        className="mt-6 w-full rounded-full bg-purple px-6 py-3 text-white"
                      >
                        Aanmelden
                      </button>
                      {error.error && (
                        <div className="mt-4 w-full rounded-xl border border-red-200 bg-red-50 p-3 text-sm font-medium text-red-400">
                          {intl.formatMessage({ id: "errorMsgDemo" })}
                        </div>
                      )}
                      {success && (
                        <div className="mt-4 w-full rounded-xl border border-green-200 bg-green-50 p-3 text-sm font-medium text-green-400">
                          {intl.formatMessage({ id: "successMsgDemo" })}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
