const faqs = [
  {
    id: 1,
    question: "Wat maakt onze service uniek?",
    answer:
      "Met de service van NabestaandenLoket worden, naast een complete checklist en afscheidspagina, ook contracten, abonnementen en (online) accounts voor nabestaanden opgezegd, gewijzigd of overgedragen. Het is een complete service, met een dagelijkse bereikbaarheid via de telefoon en de chat. Doordeweeks is NabestaandenLoket bereikbaar tussen 08:30 uur en 21:00 uur en op zaterdag tussen 09:00 uur en 21:00 uur.",
  },
  {
    id: 3,
    question: "Hoe zie ik dat er iets is opgezegd is?",
    answer:
      "Op het persoonlijke account kunnen nabestaanden een actueel statusoverzicht zien met alle opzeggingen en wijzigingen van contracten, accounts en abonnementen.",
  },
  {
    id: 4,
    question:
      "Ik wil Nabestaandenloket aan mijn nabestaanden aanbieden, en nu?",
    answer:
      "Via de website van NabestaandenLoket kunt u een kennismakingsgesprek met ons inplannen. Wij leggen u in dit gesprek graag uit hoe het platform werkt en hoe NabestaandenLoket een toegevoegde waarde kan zijn voor uw uitvaartonderneming.",
  },
  {
    id: 5,
    question: "Hoe krijg ik Nabestaandenloket op mijn website?",
    answer:
      "U ontvangt van ons een unieke url, die u aan uw eigen website kunt koppelen.",
  },
  {
    id: 6,
    question: "Hoelang blijft een account van mijn nabestaanden actief?",
    answer:
      "Totdat alles afgewikkeld is. De nabestaanden hebben geen tijdsdruk of deadline. Wij helpen de nabestaanden totdat alles 100% afgerond is.",
  },
  {
    id: 7,
    question: "Werken jullie landelijk?",
    answer:
      "Wij werken zowel landelijk, als internationaal. Recent zijn wij in België met de service gestart.",
  },
]

// eslint-disable-next-line import/prefer-default-export
export { faqs }
