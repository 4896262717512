import { useEffect, useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  let observer = null;
  if (typeof window !== 'undefined') {
    observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
  }

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  });
  return isIntersecting;
}

const TYPECLIENT = {
  company: 'company',
  heir: 'heir',
  uo: 'uo'
};

const IMPACT = {
  low: 'low',
  medium: 'medium',
  high: 'high'
};

export { classNames, useOnScreen, TYPECLIENT, IMPACT };
