import { StaticImage } from "gatsby-plugin-image"
import React, { useRef } from "react"
import { FormattedMessage } from "react-intl"
import { Autoplay, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import type { Swiper as SwiperRef } from "swiper"
import { HeadProps } from "gatsby"

import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon"
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon"

import Button from "../../components/Button"
import Layout from "../../components/Layout"
import Section from "../../components/Section"
import { closureTeam, nblTeam } from "../../content/data/team"
import HeadParent from "../../utils/header"
import TeamSEO from "../../content/seo/team"

function Team() {
  const swiperRef = useRef<SwiperRef>()

  return (
    <Layout navColor="bg-lightPurple" navTextColor="dark">
      <Section bgColor="bg-lightPurple" pt="pt-12 lg:pt-12" pb="pb-28 lg:pb-28">
        <div className="rounded-xl bg-lightPurple lg:p-14">
          <div className="mb-4 text-xl font-medium text-white">
            <FormattedMessage id="team-title" />
          </div>
          <div className="max-w-3xl font-header text-4xl text-purple lg:text-5xl">
            <FormattedMessage id="team-mission" />
          </div>
        </div>
      </Section>
      <Section bgColor="bg-sand">
        <div className="mx-auto max-w-2xl">
          <div className="mb-8 font-header text-4xl text-purple lg:text-center">
            <FormattedMessage id="team-who-we-are-title" />
          </div>
          <p className="text-justify text-lg text-purple">
            <FormattedMessage id="team-who-we-are-body" />
          </p>
        </div>
      </Section>
      <div className="mx-auto hidden max-w-7xl items-center justify-end space-x-2 lg:flex">
        <button
          type="button"
          onClick={() => {
            if (swiperRef.current) {
              return swiperRef.current.slidePrev()
            }
            return null
          }}
          className="h-10 w-10 rounded-full border-2 border-gray-200 p-2 text-gray-500 duration-300 hover:border-purple hover:bg-purple hover:text-white"
        >
          <ChevronLeftIcon />
        </button>
        <button
          type="button"
          onClick={() => {
            if (swiperRef.current) {
              return swiperRef.current.slideNext()
            }
            return null
          }}
          className="h-10 w-10 rounded-full border-2 border-gray-200 p-2 text-gray-500 duration-300 hover:border-purple hover:bg-purple hover:text-white"
        >
          <ChevronRightIcon />
        </button>
      </div>
      <div className="hidden bg-sand pt-12 pb-12 lg:block">
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          spaceBetween={10}
          centeredSlides
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}
          pagination={false}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide>
            <div className="rounded-xl bg-sand-600">
              <div className="flex flex-grow items-center space-x-16">
                <div className="min-w-72 h-96 w-96">
                  <StaticImage
                    src="../../images/cs.jpg"
                    className="h-full w-full rounded-l-xl"
                    alt="christmas tree"
                    imgStyle={{}}
                  />
                </div>
                <div className="space-y-4 pr-16">
                  <div className="font-header text-3xl text-purple">
                    Onze nabestaandendesk
                  </div>
                  <div className="text-lg text-purple">
                    Wij bieden nabestaanden ondersteuning via chat, telefoon of
                    e-mail.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-xl bg-sand-600">
              <div className="flex items-center space-x-16">
                <div className="min-w-72 h-96 w-96">
                  <StaticImage
                    src="../../images/tree.jpeg"
                    className="h-full w-full rounded-l-xl"
                    alt="christmas tree"
                    imgStyle={{}}
                  />
                </div>
                <div className="w-96 space-y-4 pr-16">
                  <div className="font-header text-3xl text-purple">
                    Ons kantoor
                  </div>
                  <div className="text-lg text-purple">
                    Ons kantoor bevindt zich in hartje Rotterdam aan het
                    Stationsplein op 7 hoog. Vanuit deze plek zet ons team zich
                    dagelijks in om uitvaartondernemers en nabestaanden zo goed
                    mogelijk van dienst te zijn.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-xl bg-sand-600">
              <div className="flex flex-grow items-center space-x-16">
                <div className="min-w-72 h-96 w-96">
                  <StaticImage
                    src="../../images/stand.jpeg"
                    className="h-full w-full rounded-l-xl"
                    alt="christmas tree"
                    imgStyle={{}}
                  />
                </div>
                <div className="w-96 space-y-4 pr-16">
                  <div className="font-header text-3xl text-purple">
                    VTU Vakbeurs
                  </div>
                  <div className="text-lg text-purple">
                    Afgelopen jaar waren wij te vinden op de VTU Vakbeurs. Dit
                    event wordt eens in de vier jaar georganiseerd voor de
                    gehele uitvaartbranche en biedt ons de mogelijkheid om meer
                    over het platform van Nabestaandenloket te vertellen.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-xl bg-sand-600">
              <div className="flex flex-grow items-center space-x-16">
                <div className="min-w-72 h-96 w-96">
                  <StaticImage
                    src="../../images/group.jpg"
                    className="h-full w-full rounded-l-xl"
                    alt="Company"
                    imgStyle={{}}
                  />
                </div>
                <div className="w-96 space-y-4 pr-16">
                  <div className="font-header text-3xl text-purple">
                    Ons team
                  </div>
                  <div className="text-lg text-purple">
                    Met ons diverse en ambitieuze team streven we altijd naar
                    het beste resultaat, waarbij de behoeften en wensen van de
                    nabestaanden centraal staan.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <Section bgColor="bg-sand" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
      <div className="grid grid-cols-1 gap-16 lg:grid-cols-3">
        <div className="text-center lg:text-left">
          <div className="mb-4 text-3xl font-medium text-purple ">
            <FormattedMessage id="team-culture-title" />
          </div>
          <Button type="primary" color="lightPurple" variant="link" link="/">
            <FormattedMessage id="team-open-jobs" />
          </Button>
        </div>
        <div className="space-y-2">
          <div className="h-8 w-8 rounded-lg bg-lightPurple p-1.5 text-white">
            <GlobeAltIcon />
          </div>
          <div className="text-xl font-medium text-purple">
            <FormattedMessage id="team-culture-one-title" />
          </div>
          <div className="text-base text-gray-600 lg:text-sm">
            <FormattedMessage id="team-culture-one-body" />
          </div>
        </div>
        <div className="space-y-2">
          <div className="h-8 w-8 rounded-lg bg-lightPurple p-1.5 text-white">
            <GlobeAltIcon />
          </div>
          <div className="text-xl font-medium text-purple">
            <FormattedMessage id="team-culture-two-title" />
          </div>
          <div className="text-base text-gray-600 lg:text-sm">
            <FormattedMessage id="team-culture-two-body" />
          </div>
        </div>
        <div className="space-y-2">
          <div className="h-8 w-8 rounded-lg bg-lightPurple p-1.5 text-white">
            <GlobeAltIcon />
          </div>
          <div className="text-xl font-medium text-purple">
            <FormattedMessage id="team-culture-three-title" />
          </div>
          <div className="text-base text-gray-600 lg:text-sm">
            <FormattedMessage id="team-culture-three-body" />
          </div>
        </div>
        <div className="space-y-2">
          <div className="h-8 w-8 rounded-lg bg-lightPurple p-1.5 text-white">
            <GlobeAltIcon />
          </div>
          <div className="text-xl font-medium text-purple">
            <FormattedMessage id="team-culture-four-title" />
          </div>
          <div className="text-base text-gray-600 lg:text-sm">
            <FormattedMessage id="team-culture-four-body" />
          </div>
        </div>
        <div className="space-y-2">
          <div className="h-8 w-8 rounded-lg bg-lightPurple p-1.5 text-white">
            <AcademicCapIcon />
          </div>
          <div className="text-xl font-medium text-purple">
            <FormattedMessage id="team-culture-five-title" />
          </div>
          <div className="text-base text-gray-600 lg:text-sm">
            <FormattedMessage id="team-culture-five-body" />
          </div>
        </div>
      </div>
     </Section> 
     */}
      {/* <Section bgColor="bg-purple">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="flex items-center">
            <div className="space-y-4">
              <div className="font-header text-4xl text-white lg:text-5xl">
                <FormattedMessage id="team-persons-title" />
              </div>
              <div className="text-lg text-sand">
                <FormattedMessage id="team-persons-body" />
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <StaticImage
              src="../../images/group_nbl.png"
              alt="group picture nabestaandenloket"
              className="rounded-xl"
            />
          </div>
        </div>
      </Section> */}
      <Section bgColor="bg-sand">
        <div className="space-y-8">
          <div className="grid grid-cols-1 gap-8 px-8 lg:grid-cols-5 lg:px-0">
            {nblTeam.map(member => (
              <div className="rounded-xl bg-white p-8" key={member.id}>
                <div className="w-28 overflow-hidden rounded-full lg:w-full">
                  {member.img}
                </div>
                <div className="mt-8">
                  <span className="text-xl font-medium text-purple lg:text-base">
                    {member.name}
                  </span>
                  <div className="text-plum text-gray-500">
                    {member.jobTitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
      <Section bgColor="bg-sand-600">
        <div className="mx-auto max-w-3xl text-center">
          <div className="font-header text-4xl text-purple">
            <FormattedMessage id="team-hiring-title" />
          </div>
          <p className="my-4 text-purple">
            <FormattedMessage id="team-hiring-body" />
          </p>
          <Button
            variant="external"
            color="purple"
            link="https://www.linkedin.com/company/closure_services/jobs/"
            type="primary"
          >
            <FormattedMessage id="team-hiring-button" />
          </Button>
        </div>
      </Section>
    </Layout>
  )
}

export default Team

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Team" location={location}>
      <TeamSEO />
    </HeadParent>
  )
}
