const messages = {
  home: 'Home',
  nabestaanden: 'Nabestaanden',
  uitvaartondernemers:'uitvaart ondernemers',
  uitvaartorganisaties: "Uitvaartorganisaties",
  executeurs: "Executeurs",
  contact: "Contact",
  oplossingen: "Oplossingen",
  resources: "Resources",
  veelgesteldeVragen: "Veelgestelde vragen",
  aboutUs: "Over ons",
  team: "Team",
  media: "Media",
  vacancies: "Vacatures",
  legal: "Juridisch",
  privacy: "Privacy",
  cookies: "Cookies",
  tos: "Algemene Voorwaarden",
  fullscreenMenuButton: "Demo inplannen",
  newsletter: "Schrijf je in voor de nieuwsletter",
  copyright:"Closure B.V. / Stationsplein 45 - Space A7.205 / 3013AM Rotterdam KVK: 70792828 © Closure 2023. Alle Rechten Voorbehouden.",
  contactHeirTitle: 'Ondersteuning voor nabestaanden',
  contactSalesTitle: 'Ondersteuning voor uitvaartondernemers',
  contactMediaTitle: 'Pers & Media',
  contactHeirBody: 'Mocht u vragen hebben over het platform kunt u ons bereiken via chat, telefoon of e-mail (info@nabestaandenloket.nl)',
  contactHeirButton: 'Chat',
  contactHeirOptional: 'Of bel ',
  contactSalesBody: 'Mocht u vragen hebben over onze dienstverlening of geïnteresseerd zijn om Nabestaandenloket aan uw nabestaanden aan te bieden. Neem contact met ons op.',
  contactSalesButton: '{link}',
  contactSalesOptional: 'Of bel',
  contactMediaBody: 'Bent u een journalist en zou u graag een stuk over Nabestaandenloket willen publiceren? Mail ons op {pers}',
  contactMediaButton: 'Mail',
  contactHeaderTitle:  'Wij helpen u graag verder',
  contactCategory: 'Contacteer ons',
  modalDemoTitle: "Demo inplannen",
  modalDemoBody: "Wij zullen zo snel mogelijk contact met u opnemen om een demo in te plannen.",
  namePlaceholder: "Naam",
  emailPlaceholder: "E-mail",
  companyPlaceholder: "Uitvaartorganisatie",
  modalDemoButton: "Aanvragen",
  "home-header-title": "Het complete nazorgplatform voor uw nabestaanden",
  "home-header-subtitle": "Verleng uw dienstverlening tot na de uitvaart. Begeleid uw nabestaanden tijdens en na de uitvaart met alle belangrijke zaken, volledig in uw eigen huisstijl.",
  "home-header-button-demo": "Boek een demo",
  "home-header-button-more-info": "Meer info",
  "home-header-feature-administration": "Administratieve zaken",
  "home-header-feature-omp": "Online afscheidspagina",
  "home-header-feature-own-branding": "Nazorg platform in uw eigen huisstijl",
  "home-card-administration-title": "Administratieve taken",
  "home-card-administration-body": "Regel alle algemene administratieve zaken.",
  "home-card-administration-completed": "8/10 afgerond",
  "home-card-financial-title": "Financiële taken",
  "home-card-financial-body": "Alle zaken regelen omtrent de bank.",
  "home-card-financial-completed": "4/10 afgerond",
  "home-administration-section-title": "Begeleid nabestaanden met alle administratieve zaken die op hun pad komen",
  "home-administration-section-body": "Na een overlijden moeten er veel zaken worden geregeld. NabestaandenLoket biedt een platform aan in uw eigen huisstijl om uw nabestaanden te voorzien van de juiste hulp en begeleiding.",
  "home-administration-feature-one": "Handleiding voor alle taken die geregeld moeten worden",
  "home-administration-feature-two": "Een duidelijk overzicht van alle zaken op één platform",
  "home-administration-feature-three": "Voortgang bijhouden en taken delegeren aan naasten",
  "home-closure-feature-cancel-service-title": "Eenvoudig abonnementen afwikkelen bij meer dan 1600 organisaties",
  "home-closure-feature-cancel-service-body": "Met de opzegdienst van Closure kunnen uw nabestaanden eenvoudig en efficiënt abonnementen afwikkelen van een overleden naaste. ",
  "home-closure-feature-cancel-service-feature-one": "Abonnementen afwikkelen bij meer dan 1600 organisaties",
  "home-closure-feature-cancel-service-feature-two": "Een persoonlijk platform met de actuele status van alle meldingen",
  "home-closure-feature-cancel-service-feature-three": "Eenmalig gegevens van overledene invullen om alle organisaties te informeren",
  "home-closure-account-card-body": "{account} is succesvol {opgezegd}",
  "home-closure-account-card-find-org": "Zoek andere organisaties",
  "home-iphone-your-logo": "uw logo",
  "home-iphone-name": "Jamie Akkersveen",
  "home-iphone-date": "1954 - 2022",
  "home-iphone-memories": "Herdenkingen",
  "home-iphone-uitvaart": "Uitvaart info",
  "home-iphone-memory-message": "Lieve allemaal, Wij willen jullie heel veel sterkte en kracht wensen met dit intense verdriet.",
  "home-iphone-memory-person": "Geplaatst door Pieter Scheelink",
  "home-iphone-memory-message-two": "Beste familie en vrienden, Heel veel sterkte en kracht toegewenst met het grote verlies! Achter elke traan die blinkt schuilt een glimlach van herinnering.",
  "home-iphone-memory-person-two": "Geplaatst door Wiera Doelschank",
  "home-iphone-funeral-info-title": "Informatie over de uitvaart",
  "home-iphone-funeral-info-date": "13 augustus 2021",
  "home-iphone-funeral-info-start": "Inloop",
  "home-iphone-funeral-info-service": "Dienst",
  "home-iphone-funeral-info-address": "Laurenskerk, Grotekerkplein 27 3011GC, Rotterdam",
  "home-omp-title":"Een online afscheidspagina voor elke fase van de uitvaart",
  "home-omp-body": "De nabestaanden kunnen de afscheidspagina volledig vormgegeven naar eigen stijl. Zo kunnen ze informatie delen en herinneringen, maar ook muziek en foto’s plaatsen.",
  "home-omp-feature-one": "Persoonlijke afscheidspagina voor nabestaanden",
  "home-omp-feature-two": "Gemakkelijk te delen via e-mail, Whatsapp en andere media",
  "home-omp-feature-three": "De mogelijkheid om de afscheidspagina weer te geven in privémodus",
  "home-video-title": "Bekijk hier een video over het NabestaandenLoket",
  "home-bereavement-support-title": "Een nabestaandendesk die klaarstaat voor uw nabestaanden",
  "home-bereavement-support-body": "De specialisten van onze Nabestaandendesk bieden nabestaanden de juiste nazorg en begeleiding nadat een naaste is overleden. Onze Nabestaandendesk is zowel telefonisch als via chat en e-mail bereikbaar.",
  "home-bereavement-support-feature-one": "Geopend van ma-vr (08:30 - 21:00) en zaterdag (09:00 - 13:00)",
  "home-bereavement-support-feature-two": "Bereikbaar via telefoon, chat en e-mail",
  "home-bereavement-support-feature-three": "Gespecialiseerd in het helpen van uw nabestaanden",
  "home-platform-demo-title": "Een gebruiksvriendelijk, digitaal platform voor nabestaanden",
  "home-platform-demo-body": "Ervaar de voordelen van NabestaandenLoket. Boek een demo.",
  "home-platform-button-demo" : "Boek een demo",
  "home-platform-tasks": "Taken",
  "home-platform-family": "Familie",
  "home-platform-welcome": "Welkom, Klaas",
  "home-platform-insurance": "Verzekeringszaken",
  "home-platform-subscription-status": "Abonnementen status",
  "home-platform-accepted": "Opgezegd (8)",
  "home-platform-pending":"In behandeling (2)",
  "home-platform-cancFelled": "Afgekeurd (1)",
  "home-platform-recent-cancellations": "Recente opzeggingen",
  "uo-header-title": "Verleng uw dienstverlening voor uw nabestaanden",
  "uo-header-body": "Voeg Nabestaandenloket toe aan uw dienstverlening om uw nabestaanden tijdens en na de uitvaart te ondersteunen.",
  "uo-header-button": "Boek een demo",
  "platform-helpdesk":" Telefonische ondersteuning",
  "platform-financial-tasks-one": "1. Afhandelen van rekeningen",
  "platform-financial-tasks-two":"2. Afhandelen van rekeningen",
  "home-platform-probate": "Erfbelasting zaken",
  "uo-trusted-by-partners": "Nabestaandenloket wordt al aangeboden door meer dan 300 uitvaartondernemers",
  "uo-benefits-title": "De voordelen van Nabestaandenloket voor u als uitvaartondernemer",
  "uo-benefits-feature-one-title": "In uw eigen huisstijl",
  "uo-benefits-feature-one-body": "Het platform van Nabestaandenloket wordt op uw website aangeboden in uw eigen huisstijl.",
  "uo-benefits-feature-two-title": "Nazorg",
  "uo-benefits-feature-two-body": "Het gespecialiseerde team van Nabestaandenloket staat altijd klaar voor uw nabestaanden om vragen te beantwoorden of ondersteuning te bieden bij andere zaken.",
  "uo-benefits-feature-three-title": "Verleng uw dienstverlening",
  "uo-benefits-feature-three-body": "Nabestaandenloket houdt u als uitvaartonderneming voor langere tijd betrokken bij nabestaanden, aangezien het platform zich voornamelijk richt op ondersteuning na de uitvaart.",
  "uo-omp-title": "De online afscheidspagina is gemakkelijk in gebruik voor al uw nabestaanden",
  "uo-omp-body": "De online afscheidspagina is gemakkelijk aan te maken door al uw nabestaanden via het platform van Nabestaandenloket.",
  "uo-omp-feature-one": "Verbreed het bereik van uw uitvaartonderneming",
  "uo-omp-feature-two": "Een online afscheidspagina in uw eigen huisstijl",
  "uo-omp-feature-three": "Bereik meer mensen bij de uitvaart",
  "uo-weekly-webinar-title":"Neem deel aan onze wekelijkse spreekuur voor meer informatie",
  "uo-weekly-webinar-subtitle": "Het wekelijkse spreekuur om meer te leren over het Nabestaandenloket platform",
  "uo-weekly-webinar-body": "Er wordt wekelijks een online spreekuur gehouden waarbij u als uitvaartorganisatie kunt aansluiten om meer over het platform van Nabestaandenloket te leren. ",
  "uo-weekly-webinar-next": "Het volgende spreekuur vindt plaats op dinsdag {date} 09:00-10:00",
  "uo-weekly-webinar-button" :"Inschrijven",
  "testimonial-text": "Het NabestaandenLoket platform heeft ervoor gezorgd dat ik mijn klanten meer aan mijn uitvaartorganisatie kan binden.",
  "testimonial-name": "Michael Hilhorst",
  "testimonial-function": "Uitvaartondernemer @ Hilhorst uitvaart",
  "team-title": "Ons team",
  "team-mission": "Maak kennis met het team achter NabestaandenLoket",
  "team-who-we-are-title": "Wie wij zijn",
  "team-who-we-are-body": "NabestaandenLoket is onderdeel van Closure en opgezet nadat er vanuit de uitvaartsector de behoefte was om een centraal platform te bieden aan nabestaanden waarop alle zaken omtrent een overlijden direct geregeld kunnen worden. Closure is een online opzegdienst waar nabestaanden gebruik van kunnen gebruiken om abonnementen te laten opzeggen, wijzigen of over te dragen na een overlijden. Deze dienstverlening is opgenomen in het platform van NabestaandenLoket samen met andere producten, zoals het afwikkelen van administratieve zaken en de online afscheidspagina. Alle producten van Nabestaandenloket zijn zichtbaar op een centraal platform in de huisstijl van de uitvaartonderneming om de band met nabestaanden te verbeteren. ",
  "team-culture-title":"Lees meer over onze cultuur & waarden",
  "team-open-jobs": "Open vacatures",
  "team-culture-one-title": "Positive impact",
  "team-culture-one-body": "When it comes to how we work, impact matters most. Freedom and responsibility go hand in hand, and while theres no micromanaging here, we strive to make a measurable impact every day. We trust each other. We hold ourselves accountable, and we move fast and hustle because we have the flexibility to excel on our own terms.",
  "team-culture-two-title": "Positive impact",
  "team-culture-two-body": "When it comes to how we work, impact matters most. Freedom and responsibility go hand in hand, and while theres no micromanaging here, we strive to make a measurable impact every day. We trust each other. We hold ourselves accountable, and we move fast and hustle because we have the flexibility to excel on our own terms.",
  "team-culture-three-title": "Positive impact",
  "team-culture-three-body": "When it comes to how we work, impact matters most. Freedom and responsibility go hand in hand, and while theres no micromanaging here, we strive to make a measurable impact every day. We trust each other. We hold ourselves accountable, and we move fast and hustle because we have the flexibility to excel on our own terms.",
  "team-culture-four-title": "Positive impact",
  "team-culture-four-body": "When it comes to how we work, impact matters most. Freedom and responsibility go hand in hand, and while theres no micromanaging here, we strive to make a measurable impact every day. We trust each other. We hold ourselves accountable, and we move fast and hustle because we have the flexibility to excel on our own terms.",
  "team-culture-five-title": "Positive impact",
  "team-culture-five-body": "When it comes to how we work, impact matters most. Freedom and responsibility go hand in hand, and while theres no micromanaging here, we strive to make a measurable impact every day. We trust each other. We hold ourselves accountable, and we move fast and hustle because we have the flexibility to excel on our own terms.",
  "team-persons-title": "Ontmoet het team achter Nabestaandenloket",
  "team-persons-body": "Ons gepassioneerde team bestaat uit professionele en ervaren medewerkers die dagelijks keihard aan Nabestaandenloket werken. Zij zijn toegewijd om nabestaanden te ondersteunen tijdens en na de uitvaart en helpen ze bij het regelen van alle belangrijke zaken. Ons team staat altijd klaar om te helpen, of het nu via de telefoon, chat of e-mail is. Wij zijn trots op ons team en hun inzet om nabestaanden te helpen in moeilijke tijden.",
  "team-hiring-title": "Kom ons team versterken",
  "team-hiring-body":" Heb jij de behoefte aan uitdagend werk binnen een snelgroeiende scale-up? Bekijk onze open vacatures.",
  "team-hiring-button": "Vacatures",
  "nabestaanden-title": "Start nu via uw uitvaartverzorger",
  "nabestaanden-body": "Nabestaandenloket ondersteunt nabestaanden bij alle relevante zaken rondom een uitvaart. Vul hieronder de naam in van uw uitvaartonderneming om erachter te komen of zij het platform van Nabestaandenloket aanbieden.",
  "nabestaanden-search": "Uitvaartorganisatie zoeken",
  "nabestaanden-no-results":"Sorry, er zijn geen resultaten gevonden.",
  "nabestaanden-cancel-service" : "Opzegdienst voor abonnementen",
  "nabestaanden-administration": "Administratieve zaken regelen",
  "nabestaanden-omp" : "Online afscheidspagina voor de uitvaart",
  "nabestaanden-relatives": "Regel zaken samen met naasten",
  "nabestaanden-video-title":"Bekijk hier hoe NabestaandenLoket u kan ondersteunen na een overlijden",
  "phonePlaceholder": "Telefoonnummer",
  "successMsgDemo": "Je aanvraag is succesvol verzonden.",
  "errorMsgDemo": "Er is iets mis gegaan. Herlaad de pagina en probeer opnieuw.",
  "home-platform-cancelled": "Niet afgerond (1)"
};
  
  export default messages;
  