import React from "react"

import { Disclosure } from "@headlessui/react"
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon"

import { classNames } from "../../utils/helpers"
import Section from "../Section"

export interface QuestionProps {
  question: string
  answer: string
  id: number
}

export interface FAQProps {
  questions: QuestionProps[]
}

export default function FAQ({ questions }: FAQProps) {
  return (
    <Section pt="pt-16 lg:pt-28" pb="pb-16 lg:pb-28" bgColor="bg-purple">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto mb-8 max-w-3xl text-center">
          <div className="font-header text-5xl text-sand lg:text-6xl">
            Veelgestelde vragen
          </div>
        </div>
        <div className="mx-auto max-w-3xl">
          <dl className="mt-6 space-y-6 divide-y-2 divide-purple-700">
            {questions.map(faq => (
              <Disclosure as="div" key={faq.id} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-sand">
                        <span className="font-medium text-white">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-sand">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </Section>
  )
}
