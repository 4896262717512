const faqs = [
  {
    id: 1,
    question: "Hoe log ik met mijn account in bij NabestaandenLoket?",
    answer:
      "U ontvangt via uw uitvaartondernemer per e-mail een link om uw persoonlijke account te starten. Voor dit account maakt u een eigen wachtwoord aan.",
  },
  {
    id: 3,
    question: "Wat moet ik doen als ik mijn wachtwoord vergeten ben?",
    answer:
      "Op het inlogscherm kunt u een nieuw wachtwoord aanvragen. U ontvangt een e-mail met daarin een link om een nieuw wachtwoord in te stellen.",
  },
  {
    id: 4,
    question: "Moet ik alles in één keer afhandelen?",
    answer:
      "Nee, u kunt alles op uw eigen tempo doen. U kunt te allen tijde in uw account inloggen om bijvoorbeeld een abonnement of contract te laten opzeggen of overdragen. Een aantal zaken moet echter eerder geregeld worden, zoals het omzetten van een kenteken van een auto. Welke zaken dit zijn, krijgt u advies over in uw persoonlijke checklist.",
  },
  {
    id: 5,
    question: "Hoe kan ik bepaalde taken door een ander laten afwikkelen?",
    answer:
      "Het account heeft één hoofdbeheerder: u als nabestaande. U heeft de rechten om andere mensen uit te nodigen om het account te gebruiken. Hierdoor kunt u samen met meerdere personen de afwikkeling regelen.",
  },
  {
    id: 6,
    question: "Kan de uitvaartorganisatie ook in mijn persoonlijke account?",
    answer:
      "Alle zaken die privacygevoelig zijn, zijn alleen voor u inzichtelijk. Voor het gedeelte van de afscheidspagina heeft de uitvaartorganisatie ook rechten om het te beheren. De afscheidspagina is een informatief gedeelte waar alle relevante informatie over de uitvaart gedeeld kan worden met naasten.",
  },
]

// eslint-disable-next-line import/prefer-default-export
export { faqs }
