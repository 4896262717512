import React from 'react';

import Section from '../Section';

export interface SimpleHeaderProps {
  title: string
  lastUpdated: string
}

export default function SimpleHeader({
  title,
  lastUpdated,
}: SimpleHeaderProps) {
  return (
    <Section pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28" bgColor="bg-sand-600">
      <div className="space-y-8 lg:px-8">
        <div className="text-left font-header text-4xl text-purple lg:text-5xl">
          {title}
        </div>
        <p className="text-plum text-lg">{lastUpdated}</p>
      </div>
    </Section>
  )
}
