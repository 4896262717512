import axios from "axios"
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from "lodash"
import React, { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import { HeadProps } from "gatsby"

import GlobeAltIcon from "@heroicons/react/24/outline/GlobeAltIcon"
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon"
import RectangleStackIcon from "@heroicons/react/24/outline/RectangleStackIcon"
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon"
import UserMinusIcon from "@heroicons/react/24/outline/UserMinusIcon"

import FAQ from "../../components/FAQ"
import Layout from "../../components/Layout"
import Section from "../../components/Section"
import { faqs } from "../../content/data/nabestaanden"
import { classNames } from "../../utils/helpers"
import HeadParent from "../../utils/header"
import NabestaandenSEO from "../../content/seo/nabestaanden"

function Nabestaanden() {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const [error, setError] = useState({ message: false })
  const changeHandler = e => {
    setQuery(e.target.value)
    axios
      .get(`${process.env.GATSBY_API_BASE_URL}/distributors`, {
        params: { q: e.target.value },
      })
      .then(res => {
        const subResult = res.data.data.splice(0, 5)
        setError({ message: false })
        setResults(subResult)
      })
      .catch(err => {
        setError({ message: err.message })
      })
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [])

  return (
    <Layout navTextColor="dark" navColor="bg-sand">
      <Section bgColor="bg-sand" pt="pt-8 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="flex w-full items-center justify-center">
            <div className="w-full rounded-2xl bg-white p-6 lg:p-10">
              <div className="mb-4 font-header text-4xl text-purple lg:text-5xl">
                <FormattedMessage id="nabestaanden-title" />
              </div>
              <div className="mb-12 text-lg text-purple">
                <FormattedMessage id="nabestaanden-body" />
              </div>
              <div className="space-y-4">
                <div className="text-lg font-medium text-purple lg:text-sm">
                  <FormattedMessage id="nabestaanden-search" />
                </div>
                <div className="relative">
                  <input
                    className="w-full rounded-lg bg-gray-50 p-3 text-sm"
                    placeholder="Zoek hier"
                    onChange={debouncedChangeHandler}
                  />
                  {error.message && (
                    <div className="mt-2 text-xs">
                      Er ging iets mis. Probeer de pagina te herladen.
                    </div>
                  )}
                  <MagnifyingGlassIcon className="absolute top-3 right-3 h-5 w-5 text-gray-300" />
                  {results && query.length > 0 && (
                    <div className="absolute top-14 z-40 w-full rounded-lg border border-gray-200 bg-white">
                      {results.map((result, index) => (
                        <div
                          className={classNames(
                            "p-5 text-sm text-purple",
                            index % 2 ? "bg-gray-50" : "",
                            "flex items-center justify-between"
                          )}
                          key={result.id}
                        >
                          <div>{result.name}</div>
                          <a
                            href={`https://${result.slug}.nabestaandenloket.nl`}
                          >
                            <span className="flex items-center justify-center rounded-md bg-lightPurple p-2 text-sm font-medium text-white">
                              Klik hier
                            </span>
                          </a>
                        </div>
                      ))}
                      {query && results.length === 0 && (
                        <div className="py-6 text-center text-sm text-purple">
                          <FormattedMessage id="nabestaanden-no-results" />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4 lg:hidden">
            <div className="flex w-full items-center rounded-2xl bg-sand-600 p-6">
              <div className="text-xl font-medium text-purple">
                <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                  <UserMinusIcon className="text-purple" />
                </div>
                <FormattedMessage id="nabestaanden-cancel-service" />
              </div>
            </div>
            <div className="flex w-full items-center rounded-2xl bg-sand-600 p-6">
              <div className="text-xl font-medium text-purple">
                <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                  <RectangleStackIcon className="text-purple" />
                </div>
                <FormattedMessage id="nabestaanden-administration" />
              </div>
            </div>
            <div className="flex w-full items-center rounded-2xl bg-sand-600 p-6">
              <div className="text-xl font-medium text-purple">
                <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                  <GlobeAltIcon className="text-purple" />
                </div>
                <FormattedMessage id="nabestaanden-omp" />
              </div>
            </div>
            <div className="flex w-full items-center rounded-2xl bg-sand-600 p-6">
              <div className="text-xl font-medium text-purple">
                <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                  <UserGroupIcon className="text-purple" />
                </div>
                <FormattedMessage id="nabestaanden-relatives" />
              </div>
            </div>
          </div>
          <div className="hidden items-center justify-end lg:flex">
            <div className="space-y-4">
              <div className="flex space-x-4">
                <div className="flex h-60 w-60 items-center rounded-2xl bg-sand-600 p-8">
                  <div className="text-xl font-medium text-purple">
                    <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                      <UserMinusIcon className="text-purple" />
                    </div>
                    <FormattedMessage id="nabestaanden-cancel-service" />
                  </div>
                </div>
                <div className="flex h-60 w-60 items-center rounded-2xl bg-sand-600 p-8">
                  <div className="text-xl font-medium text-purple">
                    <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                      <RectangleStackIcon className="text-purple" />
                    </div>
                    <FormattedMessage id="nabestaanden-administration" />
                  </div>
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex h-60 w-60 items-center rounded-2xl bg-sand-600 p-8">
                  <div className="text-xl font-medium text-purple">
                    <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                      <GlobeAltIcon className="text-purple" />
                    </div>
                    <FormattedMessage id="nabestaanden-omp" />
                  </div>
                </div>
                <div className="flex h-60 w-60 items-center rounded-2xl bg-sand-600 p-8">
                  <div className="text-xl font-medium text-purple">
                    <div className="mb-4 h-14 w-14 rounded-full bg-sand-800 p-3">
                      <UserGroupIcon className="text-purple" />
                    </div>
                    <FormattedMessage id="nabestaanden-relatives" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* <Section bgColor="bg-lightPurple">
        <div className="mx-auto max-w-4xl lg:text-center">
          <div className="mb-12 font-header text-4xl  text-purple lg:text-5xl">
            <FormattedMessage id="nabestaanden-video-title" />
          </div>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/4GlqPwIJgLg"
              title="Video homepage"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: 25,
              }}
            />
          </div>
        </div>
      </Section> */}
      <FAQ questions={faqs} />
    </Layout>
  )
}

export default Nabestaanden

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Home" location={location}>
      <NabestaandenSEO />
    </HeadParent>
  )
}
