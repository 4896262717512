import "swiper/css"

import { StaticImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"
import { HeadProps } from "gatsby"
import { FormattedMessage } from "react-intl"

// import { motion } from "framer-motion"
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon"
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon"
import CheckIcon from "@heroicons/react/24/outline/CheckIcon"
import ChevronRight from "@heroicons/react/24/outline/ChevronRightIcon"
import ComputerDesktopIcon from "@heroicons/react/24/outline/ComputerDesktopIcon"
import DocumentCheckIcon from "@heroicons/react/24/outline/DocumentCheckIcon"
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon"
import GlobeAltIcon from "@heroicons/react/24/outline/GlobeAltIcon"
import HomeIcon from "@heroicons/react/24/outline/HomeIcon"
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon"
import SwatchIcon from "@heroicons/react/24/outline/SwatchIcon"
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon"

import Button from "../components/Button"
import FAQ from "../components/FAQ"
import Layout from "../components/Layout"
import ModalDemo from "../components/ModalDemo"
import Partners from "../components/Partners"
import Section from "../components/Section"
import { subscriptions, subscriptionsApp } from "../content/data/home"
import { faqs } from "../content/data/uitvaartorganisatie"
import { classNames } from "../utils/helpers"
import HomeSEO from "../content/seo/home"
import HeadParent from "../utils/header"

function Index() {
  const [isOpen, setIsOpen] = useState(false)
  const [memorialView, setMemorialView] = useState(1)
  const howItWorksSection = useRef<HTMLDivElement>(null)

  const modalOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Layout
      navColor="bg-sand"
      navTextColor="dark"
      cta={{ text: "Boek een demo", modal: modalOpen }}
    >
      <Section bgColor="bg-sand" pt="pt-8 lg:pt-20" pb="pb-12 lg:pb-0">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="flex items-center">
            <div className="space-y-8">
              <div className="font-header text-5xl font-medium text-purple lg:text-6xl">
                <FormattedMessage id="home-header-title" />
              </div>
              <div className="text-lg text-purple">
                <FormattedMessage id="home-header-subtitle" />
              </div>
              <div className="flex space-x-4">
                <Button
                  link="/"
                  variant="modal"
                  type="primary"
                  color="pink"
                  modal={modalOpen}
                >
                  <FormattedMessage id="home-header-button-demo" />
                </Button>
                <Button
                  variant="modal"
                  modal={() => {
                    if (howItWorksSection.current !== null) {
                      howItWorksSection.current.scrollIntoView()
                    }
                  }}
                  type="primary"
                  color="sandDark"
                >
                  <FormattedMessage id="home-header-button-more-info" />
                </Button>
              </div>
              <div className="text-sm font-medium text-purple">
                Bent u een nabestaande? Klik{" "}
                <a href="/nabestaanden" className="underline">
                  <strong>hier</strong>
                </a>
              </div>
            </div>
          </div>
          <div className="relative flex w-full justify-center">
            <div className="absolute top-12 left-12 z-20 select-none">
              <div className="flex items-center justify-between rounded-full bg-white py-3 px-4">
                <div className="mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-pink-50">
                  <BuildingOfficeIcon className="h-4 w-4 text-pink-800" />
                </div>
                <div className="mr-2 text-xs font-medium text-purple-500">
                  <FormattedMessage id="home-header-feature-administration" />
                </div>
                <div className="flex w-4 items-center justify-center">
                  <CheckIcon className="text-pink" />
                </div>
              </div>
            </div>
            <div className="absolute bottom-52 right-0 z-20 select-none lg:bottom-60 lg:right-12">
              <div className="flex items-center justify-between rounded-full bg-white py-3 px-4">
                <div className="mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-pink-50">
                  <GlobeAltIcon className="h-4 w-4 text-pink-800" />
                </div>
                <div className="mr-2 text-xs font-medium text-purple-500">
                  <FormattedMessage id="home-header-feature-omp" />
                </div>
                <div className="flex w-4 items-center justify-center">
                  <CheckIcon className="text-pink" />
                </div>
              </div>
            </div>
            <div className="absolute bottom-20 left-2 z-20 select-none lg:left-20">
              <div className="flex items-center justify-between rounded-full bg-white py-3 px-4">
                <div className="mr-4 flex h-8 w-8 items-center justify-center rounded-full bg-pink-50">
                  <SwatchIcon className="h-4 w-4 text-pink-800" />
                </div>
                <div className="mr-2 text-xs font-medium text-purple-500">
                  <FormattedMessage id="home-header-feature-own-branding" />
                </div>
                <div className="flex w-4 items-center justify-center">
                  <CheckIcon className="text-pink" />
                </div>
              </div>
            </div>
            <div className="max-w-md select-none">
              <StaticImage
                src="../images/home-hero.png"
                alt="home hero"
                imgStyle={{ zIndex: 0 }}
              />
            </div>
          </div>
        </div>
      </Section>
      <Section bgColor="bg-sand">
        <Partners />
      </Section>
      <div ref={howItWorksSection}>
        <Section bgColor="bg-sand-600">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <div>
              <div className="flex items-center justify-center">
                <div className="relative flex select-none items-center justify-center">
                  <div className="absolute top-0 left-0 z-20">
                    <div className="rounded-2xl bg-white shadow-small">
                      <div className="h-36 w-72 rounded-t-2xl bg-white bg-cardImage1 bg-cover" />
                      <div className="w-72 rounded-b-2xl bg-white p-6">
                        <div className="text-lg font-medium text-purple">
                          <FormattedMessage id="home-card-administration-title" />
                        </div>
                        <div className="text-base text-purple">
                          <FormattedMessage id="home-card-administration-body" />
                        </div>
                        <div className="mt-20 flex items-center justify-between">
                          <div className="text-sm font-medium text-purple-500">
                            <FormattedMessage id="home-card-administration-completed" />
                          </div>
                          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 p-2">
                            <ChevronRight className="text-gray-500" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:left-30 absolute right-0 top-24 z-20">
                    <div className="rounded-2xl bg-white shadow-small">
                      <div className="h-36 w-72 rounded-t-2xl bg-cardImage2 bg-cover" />
                      <div className="w-72 rounded-b-2xl bg-white p-6">
                        <div className="text-lg font-medium text-purple">
                          <FormattedMessage id="home-card-financial-title" />
                        </div>
                        <div className="text-base text-purple">
                          <FormattedMessage id="home-card-financial-body" />
                        </div>
                        <div className="mt-20 flex items-center justify-between">
                          <div className="text-sm font-medium text-purple-500">
                            <FormattedMessage id="home-card-financial-completed" />
                          </div>
                          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-50 p-2">
                            <ChevronRight className="text-gray-500" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <StaticImage
                    src="../images/card-images-bg.png"
                    alt="image background"
                  />
                </div>
              </div>
            </div>
            <div className="mt-14 flex items-center sm:mt-8 lg:mt-0">
              <div className="space-y-8">
                <div className="font-header text-5xl text-purple lg:text-6xl">
                  <FormattedMessage id="home-administration-section-title" />
                </div>
                <div className="text-lg text-purple">
                  <FormattedMessage id="home-administration-section-body" />
                </div>
                <div className="space-y-4 text-purple-800">
                  <div className="flex items-center justify-start space-x-4">
                    <div className="h-8 w-8">
                      <CheckIcon className="text-pink" />
                    </div>
                    <div>
                      <FormattedMessage id="home-administration-feature-one" />
                    </div>
                  </div>
                  <div className="flex items-center justify-start space-x-4">
                    <div className="h-8 w-8">
                      <CheckIcon className="text-pink" />
                    </div>
                    <div>
                      <FormattedMessage id="home-administration-feature-two" />
                    </div>
                  </div>
                  <div className="flex items-center justify-start space-x-4">
                    <div className="h-8 w-8">
                      <CheckIcon className="text-pink" />
                    </div>
                    <div>
                      <FormattedMessage id="home-administration-feature-three" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <Section bgColor="bg-purple">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="flex items-center">
            <div className="space-y-8">
              <div className="font-header text-5xl text-sand lg:text-6xl">
                <FormattedMessage id="home-closure-feature-cancel-service-title" />
              </div>
              <div className="text-lg text-sand">
                <FormattedMessage id="home-closure-feature-cancel-service-body" />
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-sand">
                    <FormattedMessage id="home-closure-feature-cancel-service-feature-one" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-sand">
                    <FormattedMessage id="home-closure-feature-cancel-service-feature-two" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-sand">
                    <FormattedMessage id="home-closure-feature-cancel-service-feature-three" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-center">
              <div className="relative flex items-center justify-center">
                <div className="absolute z-20 w-full space-y-2 lg:w-auto">
                  {subscriptions.map((subscription, index) => (
                    // <motion.div
                    //   initial={{ opacity: 0, y: -80 }}
                    //   whileInView={{
                    //     opacity: 1,
                    //     y: 0,
                    //   }}
                    // >
                    <div
                      className="flex w-full select-none items-center space-x-4 rounded-2xl bg-white p-4 shadow-small lg:w-96"
                      key={subscription.id}
                    >
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 p-2.5">
                        {subscription.img}
                      </div>
                      <div className="text-xs font-medium">
                        {index !== 3 && (
                          <FormattedMessage
                            id="home-closure-account-card-body"
                            values={{
                              account: subscription.name,
                              opgezegd: "opgezegd",
                            }}
                          />
                        )}
                        {index === 3 && (
                          <FormattedMessage
                            id="home-closure-account-card-body"
                            values={{
                              account: subscription.name,
                              opgezegd: "omgezet naar nabestaanden",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    // </motion.div>
                  ))}
                  <div className="w-full select-none rounded-2xl bg-white p-3 lg:w-96">
                    <div className="w-full rounded-lg bg-gray-50 p-3 text-sm text-gray-400">
                      <div className="flex items-center justify-start space-x-2">
                        <MagnifyingGlassIcon className="h-5 w-5" />
                        <div>
                          <FormattedMessage id="home-closure-account-card-find-org" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <StaticImage
                  src="../images/closure-bg.png"
                  alt="image background"
                  className="select-none"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section bgColor="bg-sand">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="flex items-center justify-center">
            <div className="iphone-x select-none">
              <div className="flex w-full bg-white p-6">
                <div className="flex items-center justify-center">
                  <div className="rounded-xl bg-blue-200 p-2 text-xs font-medium text-blue-500">
                    <FormattedMessage id="home-iphone-your-logo" />
                  </div>
                </div>
              </div>
              <div className="relative h-40 w-full overflow-visible bg-abstract bg-cover">
                <div className="absolute -bottom-12 flex w-full justify-center">
                  <div className="h-36 w-36 rounded-full">
                    <StaticImage
                      src="../images/omp-profile-picture.jpg"
                      alt="profile picture online memorial page"
                      className="h-36 w-36"
                      imgStyle={{
                        borderRadius: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-14 w-full p-6">
                <div className="text-center text-xl font-medium text-purple">
                  <FormattedMessage id="home-iphone-name" />
                </div>
                <div className="text-center text-lg text-gray-400">
                  <FormattedMessage id="home-iphone-date" />
                </div>
              </div>
              <div className="grid grid-cols-2 p-6">
                <button
                  type="button"
                  onClick={() => setMemorialView(1)}
                  className={classNames(
                    "flex justify-center border-b-2 ",
                    memorialView === 1 ? "border-pink" : "border-gray-100"
                  )}
                >
                  <div className="p-2 text-sm font-medium text-purple">
                    <FormattedMessage id="home-iphone-memories" />
                  </div>
                </button>
                <button
                  type="button"
                  onClick={() => setMemorialView(2)}
                  className={classNames(
                    "flex justify-center border-b-2 ",
                    memorialView === 2 ? "border-pink" : "border-gray-100"
                  )}
                >
                  <div className="p-2 text-sm font-medium text-purple">
                    <FormattedMessage id="home-iphone-uitvaart" />
                  </div>
                </button>
              </div>
              {memorialView === 1 && (
                <div className="">
                  <div className="m-4 rounded-xl border border-gray-100 p-4">
                    <div className="text-sm text-purple">
                      <FormattedMessage id="home-iphone-memory-message" />
                    </div>
                  </div>
                  <div className="mx-4 flex items-center justify-start space-x-2">
                    <div className="h-8 w-8 rounded-full bg-purple" />
                    <div className="text-xs">
                      <FormattedMessage id="home-iphone-memory-person" />
                    </div>
                  </div>
                  <div className="m-4 rounded-xl border border-gray-100 p-4">
                    <div className="text-sm text-purple">
                      <FormattedMessage id="home-iphone-memory-message-two" />
                    </div>
                  </div>
                  <div className="mx-4 flex items-center justify-start space-x-2">
                    <div className="h-8 w-8 rounded-full bg-purple" />
                    <div className="text-xs">
                      <FormattedMessage id="home-iphone-memory-person-two" />
                    </div>
                  </div>
                </div>
              )}
              {memorialView === 2 && (
                <div className="space-y-4 px-6">
                  <div className="text-lg font-medium text-purple">
                    <FormattedMessage id="home-iphone-funeral-info-title" />
                  </div>
                  <div className="flex w-1/2 items-center justify-center rounded-xl bg-lightPurple-50 p-2 text-xs font-medium">
                    <FormattedMessage id="home-iphone-funeral-info-date" />
                  </div>
                  <div>
                    <div className="relative flex items-center justify-start">
                      <div className="absolute top-0 h-40 w-1.5 space-y-4 bg-gray-100 pt-1">
                        <div className="-ml-[5px] h-4 w-4 rounded-full border-4 border-gray-100 bg-white" />
                        <div className="-ml-[5px] h-4 w-4 rounded-full border-4 border-gray-100 bg-white" />
                      </div>
                    </div>
                    <div className="space-y-3.5 text-sm">
                      <div className="ml-6">
                        12:30 - 13:00{" "}
                        <span className="font-medium">
                          <FormattedMessage id="home-iphone-funeral-info-start" />
                        </span>
                      </div>
                      <div className="ml-6">
                        13:00 - 14:30{" "}
                        <span className="font-medium">
                          <FormattedMessage id="home-iphone-funeral-info-service" />
                        </span>
                      </div>
                      <div className="ml-6 flex items-center justify-center">
                        <div>
                          <FormattedMessage id="home-iphone-funeral-info-address" />
                        </div>
                        <div className="h-14 w-14 rounded-xl">
                          <StaticImage
                            src="../images/laurenskerk.jpeg"
                            alt="kerk"
                            className="h-full w-full rounded-xl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <div className="space-y-8">
              <div className="font-header text-5xl text-purple lg:text-6xl">
                <FormattedMessage id="home-omp-title" />
              </div>
              <div className="text-lg text-purple">
                <FormattedMessage id="home-omp-body" />
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-omp-feature-one" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-omp-feature-two" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-omp-feature-three" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* <Section bgColor="bg-purple" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="mx-auto max-w-4xl text-center">
          <div className="mb-12 font-header text-4xl text-white lg:text-5xl ">
            <FormattedMessage id="home-video-title" />
          </div>
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/wWKuCo0GI6k"
              title="Video homepage"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: 25,
              }}
            />
          </div>
        </div>
      </Section> */}
      <Section bgColor="bg-sand" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="grid grid-cols-1 gap-8 rounded-xl lg:grid-cols-2">
          <div className="flex items-center justify-center">
            <StaticImage
              src="../images/uo-heir-support.png"
              alt="operational customer support"
            />
          </div>
          <div className="flex items-center justify-center">
            <div className="space-y-8">
              <div className="font-header text-4xl text-purple sm:text-5xl lg:text-6xl">
                <FormattedMessage id="home-bereavement-support-title" />
              </div>
              <div className="text-lg text-purple">
                <FormattedMessage id="home-bereavement-support-body" />
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-bereavement-support-feature-one" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-bereavement-support-feature-two" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="home-bereavement-support-feature-three" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <div className="bg-lightPurple">
        <div className="mx-auto mb-0 max-w-4xl space-y-6 pb-12 pt-12 text-center lg:mb-24 lg:pb-0 lg:pt-24">
          <div className="px-4 font-header text-4xl text-purple lg:px-0 lg:text-6xl">
            <FormattedMessage id="home-platform-demo-title" />
          </div>
          <div className="text-lg text-purple">
            <FormattedMessage id="home-platform-demo-body" />
          </div>
          <div className="">
            <Button
              color="purple"
              type="primary"
              variant="modal"
              modal={() => setIsOpen(true)}
            >
              <FormattedMessage id="home-platform-button-demo" />
            </Button>
          </div>
        </div>
        <div className="mx-auto hidden max-w-7xl select-none md:block">
          <div className="h-10 w-full rounded-t-xl bg-purple">
            <div className="flex h-full items-center justify-start space-x-2 px-4">
              <div className="h-3 w-3 rounded-full bg-red-400" />
              <div className="h-3 w-3 rounded-full bg-yellow-400" />
              <div className="h-3 w-3 rounded-full bg-green-400" />
            </div>
          </div>
          <div className="bg-white">
            <div className="flex items-center justify-between border-b border-gray-50 px-6 py-4">
              <div className="flex w-24 items-center justify-center rounded-xl bg-blue-200 p-2 text-xs font-medium text-blue-500">
                <FormattedMessage id="home-iphone-your-logo" />
              </div>
              <div className="h-10 w-10 rounded-full bg-gray-100" />
            </div>
            <div className="flex flex-grow">
              <div className="w-24 bg-purple-700">
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <ComputerDesktopIcon className="h-6 w-6 text-lightPurple-300" />
                  <div className="font-medium text-sand">Dashboard</div>
                </div>
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <DocumentCheckIcon className="h-6 w-6 text-lightPurple-300" />

                  <div className="font-medium text-sand">
                    <FormattedMessage id="home-platform-tasks" />
                  </div>
                </div>
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <UserGroupIcon className="h-6 w-6 text-lightPurple-300" />
                  <div className="font-medium text-sand">
                    <FormattedMessage id="home-platform-family" />
                  </div>
                </div>
              </div>
              <div className="grow bg-gray-50 p-6">
                <div className="text-xl font-medium text-purple">
                  <FormattedMessage id="home-platform-welcome" />
                </div>
                <div className="grid grid-cols-2 gap-8">
                  <div className="mt-6 space-y-4">
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <BanknotesIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-card-financial-title" />
                          </div>
                        </div>
                        <div>
                          <ChevronRight className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <HomeIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-card-administration-title" />
                          </div>
                        </div>
                        <div>
                          <ChevronRight className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <DocumentIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-platform-insurance" />
                          </div>
                        </div>
                        <div>
                          <ChevronRight className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="rounded-lg bg-white p-8 shadow-sm">
                      <div className="flex h-full flex-col items-start justify-center">
                        <div className="mb-2 text-lg font-medium text-purple">
                          <FormattedMessage id="home-platform-subscription-status" />
                        </div>
                        <div className="space-y-2">
                          <div className="flex items-center space-x-2">
                            <div className="h-3 w-3 rounded-full  bg-green-400" />
                            <div className="text-sm text-purple">
                              <FormattedMessage id="home-platform-accepted" />
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div className="h-3 w-3 rounded-full  bg-blue-400" />
                            <div className="text-sm text-purple">
                              <FormattedMessage id="home-platform-pending" />
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <div className="h-3 w-3 rounded-full  bg-red-400" />
                            <div className="text-sm text-purple">
                              <FormattedMessage id="home-platform-cancelled" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 rounded-lg bg-white shadow-sm">
                      <div className="p-6 text-lg font-medium text-purple">
                        <FormattedMessage id="home-platform-recent-cancellations" />
                      </div>
                      {subscriptionsApp.map(subscription => (
                        <div
                          className="flex w-full items-center gap-4 border-t border-gray-50 p-4"
                          key={subscription.id}
                        >
                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 p-2.5">
                            {subscription.img}
                          </div>
                          <div className="text-xs font-medium">
                            <FormattedMessage
                              id="home-closure-account-card-body"
                              values={{
                                account: subscription.name,
                                opgezegd: "opgezegd",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FAQ questions={faqs} />
      <ModalDemo isOpen={isOpen} setIsOpen={setIsOpen} />
    </Layout>
  )
}

export default Index

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Home" location={location}>
      <HomeSEO />
    </HeadParent>
  )
}
