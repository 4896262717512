import React from "react"

function ErrorPageSEO() {
  return (
    <meta
      name="description"
      content="404 Error, sorry deze pagina bestaat niet."
    />
  )
}

export default ErrorPageSEO
