import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { HeadProps } from "gatsby"

import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon"
import CheckIcon from "@heroicons/react/24/outline/CheckIcon"
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon"
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon"
import ComputerDesktopIcon from "@heroicons/react/24/outline/ComputerDesktopIcon"
import DocumentCheckIcon from "@heroicons/react/24/outline/DocumentCheckIcon"
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon"
import HomeIcon from "@heroicons/react/24/outline/HomeIcon"
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon"
import PhoneIcon from "@heroicons/react/24/solid/PhoneIcon"

import Button from "../../components/Button"
import FAQ from "../../components/FAQ"
import Layout from "../../components/Layout"
import ModalDemo from "../../components/ModalDemo"
import WebinarModal from "../../components/ModalWebinar"
import Partners from "../../components/Partners"
import Section from "../../components/Section"
import { subscriptionsApp } from "../../content/data/home"
import { faqs } from "../../content/data/uitvaartorganisatie"
import HeadParent from "../../utils/header"
import UitvaartOrganisatiesSEO from "../../content/seo/uitvaartorganisaties"

function UitvaartOrganisaties() {
  const [isOpen, setIsOpen] = useState(false)
  const [isWebinarOpen, setIsWebinarOpen] = useState(false)
  const [nextWebinar, setNextWebinar] = useState(null)
  const webinarModalOpen = () => {
    setIsWebinarOpen(!isWebinarOpen)
  }
  const modalOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const d = new Date()
    const date = new Date(d.setDate(d.getDate() + ((2 + 7 - d.getDay()) % 7)))
    setNextWebinar(
      `${date.getDate()} ${date.toLocaleString("nl-NL", { month: "long" })}`
    )
  }, [])

  return (
    <Layout
      navTextColor="dark"
      navColor="bg-lightPurple"
      cta={{ text: "Boek een demo", modal: modalOpen }}
    >
      <div className="bg-lightPurple px-4 pb-1 md:pb-0">
        <div className="mx-auto mb-24 max-w-4xl space-y-6 pt-12 lg:pt-24 lg:text-center">
          <div className="font-header text-5xl text-purple lg:text-6xl">
            <FormattedMessage id="uo-header-title" />
          </div>
          <div className="max-w-2xl text-lg text-purple lg:mx-auto">
            <FormattedMessage id="uo-header-body" />
          </div>
          <div className="">
            <Button
              color="purple"
              type="primary"
              variant="modal"
              modal={() => setIsOpen(true)}
            >
              <FormattedMessage id="uo-header-button" />
            </Button>
          </div>
        </div>
        <div className="mx-auto hidden max-w-7xl select-none md:block">
          <div className="h-10 w-full rounded-t-xl bg-purple">
            <div className="flex h-full items-center justify-start space-x-2 px-4">
              <div className="h-3 w-3 rounded-full bg-red-400" />
              <div className="h-3 w-3 rounded-full bg-yellow-400" />
              <div className="h-3 w-3 rounded-full bg-green-400" />
            </div>
          </div>
          <div className="bg-white">
            <div className="flex items-center justify-between border-b border-gray-50 px-6 py-4">
              <div className="flex w-24 items-center justify-center rounded-xl bg-blue-200 p-2 text-xs font-medium text-blue-500">
                <FormattedMessage id="home-iphone-your-logo" />
              </div>
              <div className="flex items-center space-x-4">
                <div className="flex items-center justify-center rounded-xl bg-blue-200 p-2 px-4 text-xs font-medium text-blue-500">
                  <PhoneIcon className="mr-2 h-3 w-3" />
                  <FormattedMessage id="platform-helpdesk" />
                </div>
                <div className="text-sm font-medium text-purple">Jenny</div>
                <div className="h-10 w-10 rounded-full">
                  <StaticImage
                    src="../../images/stock_user.jpeg"
                    alt="profile app"
                    className="rounded-full"
                    imgStyle={{ borderRadius: 100 }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-grow">
              <div className="w-24 bg-purple-700">
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <ComputerDesktopIcon className="h-6 w-6 text-lightPurple-300" />
                  <div className="font-medium text-sand-800">Dashboard</div>
                </div>
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <DocumentCheckIcon className="h-6 w-6 text-lightPurple-300" />

                  <div className="font-medium text-sand-800">
                    <FormattedMessage id="home-platform-tasks" />
                  </div>
                </div>
                <div className="mx-4 my-6 flex flex-col items-center justify-center space-y-4 overflow-auto text-xs text-white">
                  <UserGroupIcon className="h-6 w-6 text-lightPurple-300" />
                  <div className="font-medium text-sand-800">
                    <FormattedMessage id="home-platform-family" />
                  </div>
                </div>
              </div>
              <div className="grow bg-gray-50 p-6">
                <div className="text-xl font-medium text-purple">
                  <FormattedMessage id="home-platform-welcome" />
                </div>
                <div className="grid grid-cols-2 gap-8">
                  <div className="mt-6 space-y-4">
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <BanknotesIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-card-financial-title" />
                          </div>
                        </div>
                        <div>
                          <ChevronDownIcon className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between rounded-lg bg-white p-4 shadow-sm">
                      <div className="text-sm font-medium text-purple">
                        <FormattedMessage id="platform-financial-tasks-one" />
                      </div>
                      <div>
                        <ChevronRightIcon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="flex items-center justify-between rounded-lg bg-white p-4 shadow-sm">
                      <div className="text-sm font-medium text-purple">
                        <FormattedMessage id="platform-financial-tasks-two" />
                      </div>
                      <div>
                        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-500 p-1">
                          <CheckIcon className="text-white " />
                        </div>
                      </div>
                    </div>
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <HomeIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-platform-insurance" />
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                    <div className="rounded-lg bg-white p-4 shadow-sm">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-purple p-3 text-white">
                            <DocumentIcon />
                          </div>
                          <div className="text-lg text-purple">
                            <FormattedMessage id="home-platform-probate" />
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon className="h-6 w-6 text-purple" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="rounded-lg bg-white p-8 shadow-sm">
                      <div className="flex h-full justify-start space-x-8">
                        <StaticImage
                          src="../../images/circle-diagram.png"
                          alt="cirlce diagram"
                          className="h-32 w-32 p-0"
                        />
                        <div className="flex flex-col items-start justify-center">
                          <div className="mb-2 text-lg font-medium text-purple">
                            <FormattedMessage id="home-platform-subscription-status" />
                          </div>
                          <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                              <div className="h-3 w-3 rounded-full  bg-green-400" />
                              <div className="text-sm text-purple">
                                <FormattedMessage id="home-platform-accepted" />
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <div className="h-3 w-3 rounded-full  bg-blue-400" />
                              <div className="text-sm text-purple">
                                <FormattedMessage id="home-platform-pending" />
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <div className="h-3 w-3 rounded-full  bg-red-400" />
                              <div className="text-sm text-purple">
                                <FormattedMessage id="home-platform-cancelled" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 rounded-lg bg-white shadow-sm">
                      <div className="p-6 text-lg font-medium text-purple">
                        <FormattedMessage id="home-platform-recent-cancellations" />
                      </div>
                      {subscriptionsApp.map(subscription => (
                        <div
                          className="flex w-full items-center gap-4 border-t border-gray-50 p-4"
                          key={subscription.id}
                        >
                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 p-2.5">
                            {subscription.img}
                          </div>
                          <div className="text-xs font-medium">
                            <FormattedMessage
                              id="home-closure-account-card-body"
                              values={{
                                account: subscription.name,
                                opgezegd: "opgezegd",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Section bgColor="bg-sand" pt="pt-8 lg:pt-12" pb="pb-8 lg:pb-12">
        <div className="mx-auto max-w-7xl space-y-8 text-center">
          <div className="text-lg font-medium text-purple">
            <FormattedMessage id="uo-trusted-by-partners" />
          </div>
          <Partners />
        </div>
      </Section>
      <Section bgColor="bg-sand">
        <div className="mx-auto max-w-3xl">
          <div className="mb-12 font-header text-4xl text-purple lg:text-center lg:text-5xl">
            <FormattedMessage id="uo-benefits-title" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="rounded-xl bg-sand-600 p-8">
            <div className="text-xl font-medium text-purple">
              <FormattedMessage id="uo-benefits-feature-one-title" />
            </div>
            <div className="mt-4 text-lg text-purple">
              <FormattedMessage id="uo-benefits-feature-one-body" />
            </div>
          </div>
          <div className="rounded-xl bg-sand-600 p-8">
            <div className="text-xl font-medium text-purple">
              <FormattedMessage id="uo-benefits-feature-two-title" />
            </div>
            <div className="mt-4 text-lg text-purple">
              <FormattedMessage id="uo-benefits-feature-two-body" />
            </div>
          </div>
          <div className="rounded-xl bg-sand-600 p-8">
            <div className="text-xl font-medium text-purple">
              <FormattedMessage id="uo-benefits-feature-three-title" />
            </div>
            <div className="mt-4 text-lg text-purple">
              <FormattedMessage id="uo-benefits-feature-three-body" />
            </div>
          </div>
        </div>
      </Section>
      <Section bgColor="bg-lightPurple">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="h-full select-none">
            <div className="flex h-full items-center justify-center">
              <div className="w-80 rounded-xl bg-white">
                <div className="h-8 w-full rounded-t-xl bg-purple">
                  <div className="flex h-full items-center justify-start space-x-2 px-4">
                    <div className="h-3 w-3 rounded-full bg-red-400" />
                    <div className="h-3 w-3 rounded-full bg-yellow-400" />
                    <div className="h-3 w-3 rounded-full bg-green-400" />
                  </div>
                </div>
                <div className="flex w-full bg-white p-4">
                  <div className="flex items-center justify-center">
                    <div className="rounded-xl bg-blue-200 p-2 text-xs font-medium text-blue-500">
                      <FormattedMessage id="home-iphone-your-logo" />
                    </div>
                  </div>
                </div>
                <div className="relative h-28 w-full overflow-visible bg-abstract bg-cover">
                  <div className="absolute -bottom-12 flex w-full justify-center">
                    <div className="h-36 w-36 rounded-full">
                      <StaticImage
                        src="../images/omp-profile-picture.jpg"
                        alt="profile picture online memorial page"
                        className="h-36 w-36"
                        imgStyle={{
                          borderRadius: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-14 w-full p-6">
                  <div className="text-center text-xl font-medium text-purple">
                    <FormattedMessage id="home-iphone-name" />
                  </div>
                  <div className="text-center text-lg text-gray-400">
                    <FormattedMessage id="home-iphone-date" />
                  </div>
                </div>
                <div className="grid grid-cols-2 p-6">
                  <div className="flex justify-center border-b-2 border-pink">
                    <div className="p-2 text-sm font-medium text-purple">
                      <FormattedMessage id="home-iphone-memories" />
                    </div>
                  </div>
                  <div className="flex justify-center border-b-2 border-gray-100">
                    <div className="p-2 text-sm font-medium text-purple">
                      <FormattedMessage id="home-iphone-uitvaart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex items-center lg:mt-0">
            <div className="space-y-8">
              <div className="font-header text-5xl text-purple lg:text-6xl">
                <FormattedMessage id="uo-omp-title" />
              </div>
              <div className="text-lg text-purple">
                <FormattedMessage id="uo-omp-body" />
              </div>
              <div className="space-y-4">
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="uo-omp-feature-one" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="uo-omp-feature-two" />
                  </div>
                </div>
                <div className="flex items-center justify-start space-x-4">
                  <div className="h-8 w-8">
                    <CheckIcon className="text-pink" />
                  </div>
                  <div className="text-purple">
                    <FormattedMessage id="uo-omp-feature-three" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* <Section bgColor="bg-sand">
        <div className="mx-auto max-w-3xl font-header text-5xl text-purple">
          <div className="text-left lg:text-center">
            <FormattedMessage id="uo-weekly-webinar-title" />
          </div>
        </div>
        <div className="mx-auto mt-8 flex max-w-5xl flex-col items-center justify-between space-x-8 rounded-3xl bg-white p-10 lg:mt-28 lg:flex-row">
          <div className="space-y-4">
            <div className="text-xl font-medium text-purple">
              <FormattedMessage id="uo-weekly-webinar-subtitle" />
            </div>
            <div className="text-lg text-purple">
              <FormattedMessage id="uo-weekly-webinar-body" />
            </div>
            {nextWebinar && (
              <div className="text-sm font-medium text-purple">
                <FormattedMessage
                  id="uo-weekly-webinar-next"
                  values={{ date: nextWebinar }}
                />
              </div>
            )}
            <Button
              color="purple"
              variant="modal"
              modal={() => setIsWebinarOpen(true)}
              type="primary"
            >
              <FormattedMessage id="uo-weekly-webinar-button" />
            </Button>
          </div>
          <div className="mt-8 lg:mt-0">
            <StaticImage
              src="../../images/maggie-jaap-hero.png"
              alt="Maggie header"
              className="max-w-xs lg:max-w-xl"
            />
          </div>
        </div>
      </Section> */}
      <Section bgColor="bg-lightPurple">
        <div className="mx-auto max-w-3xl lg:text-center">
          <div className="font-header text-3xl text-purple">
            &quot;
            <FormattedMessage id="testimonial-text" />
            &quot;
          </div>
          {/* <div className="mt-8 flex items-center justify-center space-x-4">
            <div className="h-12 w-12 rounded-full">
              <StaticImage
                src="../../images/michael.png"
                alt="profile app"
                className="rounded-full"
                imgStyle={{ borderRadius: 100 }}
              />
            </div>
            <div>
              <div className="text-left text-xl font-medium text-purple">
                <FormattedMessage id="testimonial-name" />
              </div>
              <div className="text-left text-lg text-purple-100">
                <FormattedMessage id="testimonial-function" />
              </div>
            </div>
          </div> */}
        </div>
      </Section>
      <FAQ questions={faqs} />
      <ModalDemo isOpen={isOpen} setIsOpen={modalOpen} />
      <WebinarModal
        isOpen={isWebinarOpen}
        setIsOpen={webinarModalOpen}
        nextWebinar={nextWebinar}
      />
    </Layout>
  )
}

export default UitvaartOrganisaties

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Uitvaartorganisaties" location={location}>
      <UitvaartOrganisatiesSEO />
    </HeadParent>
  )
}
