import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const nblTeam = [
  {
    id: 1,
    name: "Graciëlla van Vliet",
    img: (
      <StaticImage
        src="../../images/team/graciella.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="graciella"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Co-founder | CEO",
  },
  {
    id: 2,
    name: "Bart Verhaegh",
    img: (
      <StaticImage
        src="../../images/team/bart.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="bart"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Co-founder | CTO",
  },
  {
    id: 4,
    name: "Jochem Meeuwissen",
    img: (
      <StaticImage
        src="../../images/team/jochem.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="jochem"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Developer",
  },
  // {
  //   id: 6,
  //   name: 'Meynard Mangabang',
  //   img: (
  //     <StaticImage
  //       src="../../images/team/meynard.png"
  //       placeholder="none"
  //       className="mb-8 w-40 rounded-full"
  //       alt="meynard"
  //       imgStyle={{ borderRadius: '100%' }}
  //     />
  //   ),
  //   jobFunction: 'Senior Developer'
  // },
  {
    id: 7,
    name: "Michael Hilhorst",
    img: (
      <StaticImage
        src="../../images/team/michael.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="michael"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Product",
  },
  {
    id: 9,
    name: "Iris van Mil",
    img: (
      <StaticImage
        src="../../images/team/iris.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="iris"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Support",
  },
  {
    id: 11,
    name: "Sanne Scheltinga",
    img: (
      <StaticImage
        src="../../images/team/sanne.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="sanne"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "People & Culture",
  },
  {
    id: 13,
    name: "Andrea Raes",
    img: (
      <StaticImage
        src="../../images/team/andrea.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="sanne"
        imgStyle={{ borderRadius: "100%" }}
      />
    ),
    jobFunction: "Support",
  },
]

const closureTeam = []

export { nblTeam, closureTeam }
