import React from "react"

function AlgemeneVoorwaardenSEO() {
  return (
    <>
      <meta property="og:title" content="Algemene voorwaarden" />
      <meta
        name="description"
        property="og:description"
        content="Algemene voorwaarden van Nabestaandenloket"
      />
    </>
  )
}

export default AlgemeneVoorwaardenSEO
