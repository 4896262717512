import type { HeadProps } from "gatsby"
import React from "react"

export interface HeadParentProps {
  children: React.ReactNode
  pageTitle: string
  location?: HeadProps["location"]
}
export default function HeadParent({
  children,
  pageTitle,
  location,
}: HeadParentProps) {
  return (
    <>
      <title id="title">{pageTitle} - NabestaandenLoket</title>
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css"
      />
      {location && (
        <link
          rel="canonical"
          href={`https://nabestaandenloket.nl${location.pathname}`}
        />
      )}
      <meta name="robots" content="index, follow" />
      <meta property="og:url" content="https://nabestaandenloket.nl/" />
      <meta property="og:locale" content="nl_NL" />
      <meta property="og:site_name" content="Closure" />
      <meta name="og:type" content="website" />
      <meta name="theme-color" content="#180048" />
      <meta charSet="utf-8" />
      {children}
    </>
  )
}
