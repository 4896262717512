import { HeadProps } from "gatsby"
import React from "react"
import { FormattedMessage } from "react-intl"

import Button from "../components/Button"
import Layout from "../components/Layout"
import ErrorPageSEO from "../content/seo/404"
import HeadParent from "../utils/header"

function NotFoundPage() {
  return (
    <Layout
      navColor="bg-primary"
      navTextColor="white"
      cta={{
        text: <FormattedMessage id="backToHome" />,
        link: "https://nabestaandenloket.nl",
      }}
    >
      <div className="bg-primary -mt-20 flex h-screen flex-col items-center justify-center space-y-8">
        <div className="text-offwhite font-header text-5xl lg:text-8xl">
          404
        </div>
        <div className="text-offwhite text-lg">
          Sorry, deze pagina bestaat niet.
        </div>
        <Button link="/" variant="link" type="primary" color="purple">
          Ga terug naar de homepagina
        </Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="404" location={location}>
      <ErrorPageSEO />
    </HeadParent>
  )
}
