import { Link } from "gatsby"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

import { Popover, Transition } from "@headlessui/react"
import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon"
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon"

import { mobileNavItems, navMenu } from "../../../content/data/navigation"
import { ReactComponent as LogoDark } from "../../../images/logo/logo-dark.svg"
import { ReactComponent as LogoLight } from "../../../images/logo/logo-light.svg"
import { classNames } from "../../../utils/helpers"
import Button from "../../Button"

export interface CTAProps {
  link?: string
  text: string | JSX.Element
  modal?: Function
}

interface NavProps {
  cta?: CTAProps
  navColor: string
  navTextColor: "white" | "dark"
}

function Nav({ cta, navColor, navTextColor }: NavProps) {
  const [y, setY] = useState(0)
  const [navOpen, setNavOpen] = useState(false)
  const [scrollingUp, setScrollingUp] = useState(false)

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget
      if (y > window.scrollY) {
        setScrollingUp(true)
      } else if (y < window.scrollY) {
        setScrollingUp(false)
      }
      setY(window.scrollY)
    },
    [y]
  )

  useEffect(() => {
    if (typeof window !== "undefined") {
      setY(window.scrollY)
    }
  }, [])

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  return (
    <Popover
      className={classNames(
        navOpen
          ? "sticky top-0 shadow-none "
          : scrollingUp
          ? "sticky top-0"
          : "sticky -top-20 shadow-none sm:-top-20",
        y < 80 ? `shadow-none ${navColor}` : "bg-sand shadow-sm",
        "z-40 bg-sand transition-all duration-300 ease-out"
      )}
    >
      {({ open }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          setNavOpen(open)
        }, [open])
        return (
          <header
            className={classNames(
              open ? "bg-white" : "sticky top-0",
              "delay-50 transition-colors duration-200"
            )}
          >
            <nav className="mx-auto max-w-7xl px-4">
              <div className="flex h-20 w-full items-center justify-between">
                <div className="flex items-center md:pl-0">
                  <Link to="/">
                    {open ? (
                      <LogoLight className="h-4 w-auto md:h-6" />
                    ) : navTextColor === "white" && y < 80 ? (
                      <LogoDark className="h-4 w-auto md:h-6" />
                    ) : (
                      <LogoLight className="h-4 w-auto md:h-6" />
                    )}
                  </Link>
                </div>
                <div className="lg:hidden">
                  {open ? (
                    <Popover.Button
                      className={classNames(
                        open
                          ? "bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                          : "text-plum  hover:text-plum-200 bg-sand",
                        "focus:none inline-flex items-center justify-center rounded-md p-2"
                      )}
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </Popover.Button>
                  ) : (
                    <Popover.Button
                      className={classNames(
                        open
                          ? "bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                          : "text-plum hover:text-plum-200 bg-sand hover:bg-sand",
                        "focus:none inline-flex items-center justify-center rounded-md p-2"
                      )}
                    >
                      <Bars3Icon className="h-6 w-6" />
                    </Popover.Button>
                  )}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute inset-0 z-10 mt-20 h-screen w-screen transform">
                      <div className="absolute inset-0 bg-white px-4 pb-20 pt-0">
                        {mobileNavItems.map((navItemGroup, indexHeaders) => (
                          <div
                            key={navItemGroup.id}
                            className={classNames(
                              indexHeaders > 0 ? "pt-6" : "",
                              "pb-2"
                            )}
                          >
                            <div className="text-darkblue pb-2 text-lg font-medium">
                              {navItemGroup.placeholder}
                            </div>
                            {navItemGroup.navItems.map(navItem => (
                              <Link
                                key={navItem.id}
                                to={navItem.url}
                                className="text-plum hover:text-plum-200 flex h-10 flex-row items-center text-sm lg:text-base"
                              >
                                {navItem.name}
                              </Link>
                            ))}
                          </div>
                        ))}
                        {cta && (
                          <button
                            className="hover:bg-plum-200 mt-6 w-full rounded-lg bg-purple p-4 text-sm text-white"
                            type="button"
                            onClick={() => cta.modal()}
                          >
                            <FormattedMessage id="fullscreenMenuButton" />
                          </button>
                        )}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </div>
                <div className="hidden space-x-8 text-sm md:ml-10 lg:block">
                  {navMenu.map(menu => {
                    if (menu.url.includes("https://")) {
                      return (
                        <a
                          key={menu.id}
                          className={classNames(
                            y > 80
                              ? "text-plum hover:text-darkblue-300"
                              : navTextColor === "dark"
                              ? "text-plum hover:text-darkblue-300"
                              : "text-offwhite hover:text-plum"
                          )}
                          href={menu.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {menu.name}
                        </a>
                      )
                    }
                    return (
                      <Link
                        key={menu.id}
                        className={classNames(
                          y > 80
                            ? "text-plum hover:text-darkblue-300"
                            : navTextColor === "dark"
                            ? "text-plum hover:text-darkblue-300"
                            : "text-offwhite hover:text-plum"
                        )}
                        to={menu.url}
                        activeClassName="text-plum font-medium"
                      >
                        {menu.name}
                      </Link>
                    )
                  })}
                  {cta && (
                    <Button
                      variant={
                        cta.modal
                          ? "modal"
                          : cta.link.includes("http")
                          ? "external"
                          : "link"
                      }
                      type="primary"
                      color="purple"
                      link={cta.link}
                      modal={cta.modal}
                    >
                      {cta.text}
                    </Button>
                  )}
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    </Popover>
  )
}

export default Nav
