import React from "react"

import { classNames } from "../../utils/helpers"

export interface SectionProps {
  pt?: string
  pb?: string
  bgColor: string
  children: JSX.Element[] | JSX.Element
}
function Section({
  pt = "pt-12 lg:pt-28",
  pb = "pb-12 lg:pb-28",
  bgColor,
  children,
}: SectionProps) {
  return (
    <div className={classNames(pt, " ", pb, " ", bgColor)}>
      <div className="mx-auto max-w-7xl px-4">{children}</div>
    </div>
  )
}

export default Section
