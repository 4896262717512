import { HeadProps } from "gatsby"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "../../components/Button"
import Layout from "../../components/Layout"
import ModalDemo from "../../components/ModalDemo"
import Section from "../../components/Section"
import ContactSEO from "../../content/seo/contact"
import HeadParent from "../../utils/header"

function Contact() {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    window.Trengo = window.Trengo || {}
  }, [])
  return (
    <Layout navColor="bg-sand" navTextColor="dark">
      <Section bgColor="bg-sand" pt="pt-8 lg:pt-28" pb="pb-16 lg:pb-28">
        <div className="mx-auto max-w-xl">
          <div className="mb-8 text-center text-lg font-medium text-pink">
            <FormattedMessage id="contactCategory" />
          </div>
          <div className="text-center font-header text-4xl text-purple lg:text-5xl">
            <FormattedMessage id="contactHeaderTitle" />
          </div>
        </div>
      </Section>
      <Section bgColor="bg-sand" pt="pt-0" pb="pb-16 lg:pb-28">
        <div className="mx-auto grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className="flex flex-col items-center justify-center space-y-4 rounded-xl bg-purple p-10 text-center">
            <div className="font-header text-2xl text-sand">
              <FormattedMessage id="contactHeirTitle" />
            </div>
            <p className="text-base text-sand">
              <FormattedMessage id="contactHeirBody" />
            </p>
            <div className="flex items-center space-x-4">
              <Button
                type="primary"
                color="sand"
                variant="modal"
                modal={() => window.Trengo.Api.Widget.open("chat")}
              >
                <FormattedMessage id="contactHeirButton" />
              </Button>
              <div className="text-sand">
                <FormattedMessage id="contactHeirOptional" />{" "}
                <span className="font-medium">085 06 45 744</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 rounded-xl bg-pink p-10 text-center">
            <div className="font-header text-2xl text-purple">
              <FormattedMessage id="contactSalesTitle" />
            </div>
            <p className="text-base text-purple-800">
              <FormattedMessage id="contactSalesBody" />
            </p>
            <div className="flex items-center space-x-4">
              <Button
                type="primary"
                color="purple"
                link="mailto:info@nabestaandenloket.nl"
                variant="external"
              >
                <FormattedMessage
                  id="contactSalesButton"
                  values={{
                    link: "Mail",
                  }}
                />
              </Button>
              <div className="text-purple">
                <FormattedMessage id="contactHeirOptional" />{" "}
                <span className="font-medium">085 06 45 744</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between space-y-4 rounded-xl bg-lightPurple p-10 text-center">
            <div className="text-offwhite font-header text-2xl">
              <FormattedMessage id="contactMediaTitle" />
            </div>
            <p className="text-offwhite text-base">
              <FormattedMessage
                id="contactMediaBody"
                values={{ pers: "pers@closure.nl" }}
              />
            </p>
            <Button
              type="primary"
              color="purple"
              link="mailto:pers@closure.nl"
              variant="external"
            >
              Mail
            </Button>
          </div>
        </div>
      </Section>
      <ModalDemo isOpen={isOpen} setIsOpen={setIsOpen} />
    </Layout>
  )
}

export default Contact

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Contact" location={location}>
      <ContactSEO />
    </HeadParent>
  )
}
