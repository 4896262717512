import React from "react"
import Layout from "../../components/Layout"
import Section from "../../components/Section"

export default function AfscheidsPaginaVoorbeeld() {
  return (
    <Layout navColor="bg-sand" navTextColor="dark">
      <Section bgColor="bg-sand" pt="pt-8 lg:pt-28" pb="pb-12 lg:pb-0">
        <div className="mx-auto max-w-4xl">
          <div className="mb-12 text-center font-header text-5xl font-medium text-purple lg:text-6xl">
            Afscheidspagina voorbeeld
          </div>
          <div className="flex justify-center">
            <iframe
              src="https://demo.nabestaandenloket.nl/mijn-afscheidspaginas/"
              title="afscheidspagina register"
              height="800px"
              width="800px"
            />
          </div>
        </div>
      </Section>
    </Layout>
  )
}
