import "swiper/css/autoplay"

import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

export interface PartnersProps {
  color?: "color" | "white" | "blue"
}

function Partners() {
  const [domLoaded, setDomLoaded] = useState(false)
  const companies: { id: number; logo: JSX.Element }[] = [
    {
      id: 1,
      logo: (
        <StaticImage
          src="../../images/partners/cuvo-color.png"
          className="h-14 w-28"
          alt="CUVO uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 2,
      logo: (
        <StaticImage
          src="../../images/partners/bennis.png"
          className="h-14 w-28"
          alt="Bennis uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 3,
      logo: (
        <StaticImage
          src="../../images/partners/associatie.png"
          className="h-14 w-28"
          alt="associatie uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 4,
      logo: (
        <StaticImage
          src="../../images/partners/zuylen.png"
          className="h-14 w-28"
          alt="zuylen uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 5,
      logo: (
        <StaticImage
          src="../../images/partners/de_groot.png"
          className="h-14 w-28"
          alt="De Groot uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 6,
      logo: (
        <StaticImage
          src="../../images/partners/arensman.png"
          className="h-14 w-28"
          alt="Arensman uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 7,
      logo: (
        <StaticImage
          src="../../images/partners/mieke_greveling.png"
          className="h-14 w-28"
          alt="Mieke Greveling uitvaart"
          placeholder="none"
        />
      ),
    },
    {
      id: 8,
      logo: (
        <StaticImage
          src="../../images/partners/uitvaartzorg-bollenstreek.png"
          alt="Bollenstreek uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 9,
      logo: (
        <StaticImage
          src="../../images/partners/stoppelenburg-uitvaartverzorging.png"
          alt="Stoppelenburg uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 10,
      logo: (
        <StaticImage
          src="../../images/partners/pc-uitvaart.png"
          alt="PC uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 11,
      logo: (
        <StaticImage
          src="../../images/partners/boddenberg.png"
          alt="Boddenberg uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 12,
      logo: (
        <StaticImage
          src="../../images/partners/de_regt.png"
          alt="De Regt uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 13,
      logo: (
        <StaticImage
          src="../../images/partners/funeral_company.png"
          alt="Funeral Company uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 14,
      logo: (
        <StaticImage
          src="../../images/partners/alma.png"
          alt="Alma uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 16,
      logo: (
        <StaticImage
          src="../../images/partners/hoogvliet.png"
          alt="Hoogvliet uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 17,
      logo: (
        <StaticImage
          src="../../images/partners/de_haan.png"
          alt="De Haan uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
    {
      id: 18,
      logo: (
        <StaticImage
          src="../../images/partners/de_eer.png"
          alt="De Laatste Eer uitvaart"
          className="h-14 w-28"
          placeholder="none"
        />
      ),
    },
  ]

  useEffect(() => {
    setDomLoaded(true)
  }, [])
  if (domLoaded) {
    return (
      <Swiper
        modules={[Autoplay]}
        spaceBetween={10}
        loop
        autoplay={{
          delay: 2000,
        }}
        speed={4000}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
      >
        {companies.map(company => (
          <SwiperSlide
            className="flex items-center justify-center"
            key={company.id}
          >
            {company.logo}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }
  return null
}
export default Partners
