exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-afscheidspagina-index-tsx": () => import("./../../../src/pages/afscheidspagina/index.tsx" /* webpackChunkName: "component---src-pages-afscheidspagina-index-tsx" */),
  "component---src-pages-algemene-voorwaarden-index-tsx": () => import("./../../../src/pages/algemene-voorwaarden/index.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nabestaanden-index-tsx": () => import("./../../../src/pages/nabestaanden/index.tsx" /* webpackChunkName: "component---src-pages-nabestaanden-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-uitvaartorganisaties-index-tsx": () => import("./../../../src/pages/uitvaartorganisaties/index.tsx" /* webpackChunkName: "component---src-pages-uitvaartorganisaties-index-tsx" */)
}

