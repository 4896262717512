import "../../styles/global.css"

import React, { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import CookiesConsent from "react-cookie-consent"
import { Link, Script } from "gatsby"
import axios from "axios"

import { LOCALES, MESSAGES } from "../../locales"
import Footer from "./Footer"
import Nav, { CTAProps } from "./Nav"
import Banner from "../Banner"

export interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  navColor: string
  navTextColor: "white" | "dark"
  cta?: CTAProps
}

function Layout({ children, navColor, navTextColor, cta }: LayoutProps) {
  const [banner, setBanner] = useState(null)
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      axios.get(`${process.env.GATSBY_API_BASE_URL}/announcement`).then(res => {
        if (res.status === 200 && res.data) {
          setBanner(res.data)
        }
      })
    }
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <IntlProvider
      messages={MESSAGES[LOCALES.DUTCH]}
      locale="en"
      defaultLocale="en"
    >
      <Script id="trengo">
        {`window.Trengo = window.Trengo || {};
          window.Trengo.key = '${process.env.GATSBY_TRENGO_KEY}';
          (function(d, script, t) {
              script = d.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              script.src = 'https://static.widget.trengo.eu/embed.js';
              d.getElementsByTagName('head')[0].appendChild(script);
              }(document));`}
      </Script>
      {banner && <Banner color="bg-purple">{banner}</Banner>}
      <Nav navColor={navColor} navTextColor={navTextColor} cta={cta} />
      {children}
      <Footer />
      <CookiesConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        containerClasses="container-cookie"
        disableStyles
        flipButtons
        style={{}}
        buttonStyle={{
          textAlign: "center",
          background: "#180048",
          color: "#FFFBF5",
          padding: "8px 20px",
          borderRadius: "5px",
          marginRight: "14px",
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        <div className="text-plum mx-auto mr-0 max-w-full text-left text-xs lg:mr-8">
          We gebruiken cookies om uw browserervaring te verbeteren en
          siteverkeer te analyseren. Door verder te bladeren of deze banner te
          sluiten, gaat u akkoord met ons gebruik van cookies. Lees hier meer
          over in ons{" "}
          <span className="text-primary font-medium underline">
            <Link to="/cookies">cookies beleid</Link>.
          </span>
        </div>
      </CookiesConsent>
    </IntlProvider>
  )
}

export default Layout
