import React from "react"

function TeamSEO() {
  return (
    <meta
      name="description"
      content="Het Nabestaandenloket team is een ambitieus team en onderdeel van Closure. Closure biedt een opzegdienst aan rondom het afwikkelen van abonnementen. Nabestaandenloket is een verlengde hiervan om specifiek de uitvaart sector te voorzien van de juiste digitale middelen."
    />
  )
}

export default TeamSEO
