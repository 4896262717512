import React from "react"

function UitvaartOrganisatiesSEO() {
  return (
    <meta
      name="description"
      content="Het Nabestaandenloket platform kan aan nabestaanden van uw uitvaartorganisatie worden aangeboden. Volledig in uw eigen huisstijl. Verleng uw dienstverlening to na de uitvaart met het Nabestaandenloket platform"
    />
  )
}

export default UitvaartOrganisatiesSEO
