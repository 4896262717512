import { HeadProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../../components/Layout"
import Section from "../../components/Section"
import SimpleHeader from "../../components/SimpleHeader"
import AlgemeneVoorwaardenSEO from "../../content/seo/algemene-voorwaarden"
import HeadParent from "../../utils/header"

function AlgemeneVoorwaarden() {
  return (
    <Layout navColor="bg-sand-600" navTextColor="dark">
      <SimpleHeader
        title="Nabestaandenloket - Algemene Voorwaarden"
        lastUpdated="22 mei 2018"
      />
      <Section bgColor="bg-sand" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="mx-auto max-w-7xl px-8">
          <a href="https://www.closure.nl/GebruiksvoorwaardenClosure.pdf">
            <div className="flex w-72 items-center justify-between rounded-lg border border-gray-200 p-4">
              <div className="flex h-8 w-8 items-center justify-center p-1">
                <StaticImage
                  src="../../images/pdf_file.png"
                  alt="pdf icon"
                  className="h-8 w-8"
                />
              </div>
              <div>
                <div className="text-sm font-medium text-purple">
                  Algemene voorwaarden
                </div>
                <div className="text-sm text-purple">22 mei 2018</div>
              </div>
            </div>
          </a>
        </div>
      </Section>
    </Layout>
  )
}

export default AlgemeneVoorwaarden

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Home" location={location}>
      <AlgemeneVoorwaardenSEO />
    </HeadParent>
  )
}
