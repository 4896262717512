import { HeadProps } from "gatsby"
import React from "react"

import Layout from "../../components/Layout"
import Section from "../../components/Section"
import SimpleHeader from "../../components/SimpleHeader"
import CookiesSEO from "../../content/seo/cookies"
import HeadParent from "../../utils/header"

function Cookies() {
  return (
    <Layout navColor="bg-sand-600" navTextColor="dark">
      <SimpleHeader
        title="Nabestaandenloket - Cookies"
        lastUpdated="18 mei 2018"
      />
      <Section bgColor="bg-sand" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="mx-auto max-w-3xl">
          <div className="text-plum space-y-4 text-base">
            <div className="text-lg font-medium">
              1. Het cookiebeleid van Closure
            </div>
            <p>
              Dit is het cookiebeleid van Closure B.V. (hierna te noemen
              “Closure”, &quot;wij,&quot; &quot;ons&quot; of &quot;onze&quot;),
              een onderneming met adres Stationsplein 45, A7.209, 3013AM
              Rotterdam. Closure is ingeschreven bij de Kamer van Koophandel
              onder nummer 70792828.
            </p>
            <p>
              Closure respecteert uw privacy en doet er alles aan om uw gegevens
              te beschermen. We vinden het belangrijk dat onze service
              transparant, persoonlijk en betrouwbaar is. We zijn dan ook
              voortdurend op zoek naar manieren om onze dienstverlening te
              verbeteren en deze zoveel mogelijk af te stemmen op uw
              persoonlijke wensen en behoeften. Daarbij verwerken we uw gegevens
              zorgvuldig en veilig.
            </p>
            <p>
              Dit cookiebeleid legt uit hoe wij cookies gebruiken en hoe wij uw
              gegevens delen en beschermen in relatie tot onze website
              www.closure.nl en applicatie www.service.closure.nl (de
              “Website”). Het plaatsen van cookies en het verwerken van
              persoonsgegevens gebeurt op een wijze welke in overeenstemming is
              met de Algemene Verordening Gegevensbescherming, inclusief
              uitvoeringswet van deze verordening, of de voorafgaande wetgeving
              van de Wet Bescherming Persoonsgegevens (de “AVG”), de
              telecommunicatiewet en alle overige privacywetgeving zoals die op
              dit moment geldt.
            </p>
            <h1 id="2-wat-zijn-cookies-">
              <strong>2. Wat zijn cookies?</strong>
            </h1>
            <p>
              Cookies zijn eenvoudige tekstbestanden die op uw computer, tablet
              of telefoon worden opgeslagen, wanneer u onze Website bezoekt.
              Cookies kunnen uw computer, tablet of telefoon niet beschadigen.
              De opgeslagen informatie kan bij een volgend bezoek weer naar onze
              servers teruggestuurd worden. De cookies zijn noodzakelijk om u
              een prettige ervaring op de Website te geven.
            </p>
            <p>
              <strong>Functionele cookies</strong>
            </p>
            <p>
              Functionele cookies zijn cookies die noodzakelijk zijn om onze
              Website goed te laten werken. Zonder deze cookies kan onze Website
              niet goed functioneren. U kunt deze cookies dan ook niet
              uitzetten. Er bestaat een onderscheid tussen permanente
              functionele cookies en sessie cookies. De sessie cookies worden
              direct na uw bezoek van onze site verwijderd. De permanente
              cookies worden bewaard om te voorkomen dat u niet telkens opnieuw
              dezelfde actie hoeft uit te voeren. Een voorbeeld hiervan zijn
              aankopen die in een winkelwagen van een webshop voor u bewaard
              worden. Wij delen de gegevens die wij inwinnen via deze cookies
              dan ook niet met derden.
            </p>
            <p>
              <strong>Analytische cookies</strong>
            </p>
            <p>
              Analytische cookies zijn cookies die ervoor zorgen dat de site
              goed kan functioneren. Door analytische cookies weten wij
              bijvoorbeeld waar op onze site naar wordt gezocht, hoe lang het
              duurt voordat pagina’s geladen zijn en waarop op onze Website door
              onze bezoekers wordt geklikt. Analytische cookies mogen soms
              worden geplaatst zonder uw toestemming. Andere analytische cookies
              worden uiteraard alleen met uw toestemming geplaatst.
            </p>
            <p>
              <strong>Google Analytics:</strong> Voor onze analytische diensten
              maken we gebruik van Google Analytics van het Amerikaanse bedrijf
              Google. Wij gebruiken deze dienst om bij te houden en rapportages
              te ontvangen over hoe bezoekers de Website gebruiken. Google
              Analytics plaatst een permanent cookie om uw webbrowser te
              identificeren. Hierdoor worden uw gegevens gedeeld met Google. Wij
              delen alleen gegevens met Google die wij op basis van de AVG met
              Google mogen delen. Wij gebruiken deze informatie met informatie
              van andere Gebruikers. Hierdoor is het voor ons niet mogelijk om u
              als individu te kunnen identificeren. Wij hebben Google geen
              toestemming gegeven om uw gegevens te delen met derden. U kunt
              volledig voorkomen dat Google Analytics u herkent door de cookies
              uit te schakelen in uw browser. Google kan wel verplicht worden de
              gegevens aan derden te verschaffen indien Google daartoe wettelijk
              verplicht wordt. De informatie die Google verzamelt, wordt zo veel
              mogelijk geanonimiseerd. Uw IP-adres wordt nadrukkelijk niet
              meegegeven. De informatie wordt door Google opgeslagen op servers
              in de Verenigde Staten. Google houdt zich aan de Privacy Shield
              principles en is aangesloten bij het Privacy Shield-programma van
              het Amerikaanse Ministerie van Handel. Dit betekent dat er sprake
              is van een passend beschermingsniveau voor de verwerking van
              eventuele persoonsgegevens.
            </p>
            <p>
              Naast Google Analytics maken wij op de Website gebruik van de
              volgende analytische cookies: Hotjar en Tawk.to.
            </p>
            <p>
              <strong>Sociale media cookies</strong>
              <br />
              Op deze pagina zijn buttons van sociale netwerken als Facebook en
              Twitter opgenomen. Deze buttons werken door stukjes code die van
              deze netwerken zelf afkomstig zijn. Door middel van deze code
              worden cookies op de Website geplaatst. Wij hebben daar geen
              invloed op. Leest u de privacyverklaring van het respectievelijke
              sociale mediabedrijf (welke regelmatig kunnen wijzigen) om te
              lezen wat zij met uw (persoons)gegevens doen die zij via deze
              cookies verwerken.
            </p>
            <p>
              De informatie die ze verzamelen wordt zo veel mogelijk
              geanonimiseerd. Bedrijven als LinkedIn, Twitter, Facebook en
              Google + houden zich aan de Privacy Shield principes en zijn
              aangesloten bij het Privacy Shield-programma van het Amerikaanse
              Ministerie van Handel. Dit houdt in dat er sprake is van een
              passend beschermingsniveau voor de verwerking van eventuele
              persoonsgegevens zoals bedoeld in de AVG.
            </p>
            <h1 id="3-recht-op-inzage-correcties-recht-op-bezwaar-en-recht-op-dataportabiliteit">
              <strong>
                3. Recht op inzage, correcties, recht op bezwaar en recht op
                dataportabiliteit
              </strong>
            </h1>
            <p>
              Wanneer u inzage wil in uw persoonsgegevens, als u uw gegevens wil
              wijzigingen of verwijderen of als u uw persoonsgegevens geheel of
              gedeeltelijk wil laten overdragen aan u of aan een derde, dan kunt
              u contact opnemen met Closure door een e-mail te sturen naar
              info@closure.nl of een brief sturen aan:
            </p>
            <p>Closure B.V.</p>
            <p>Stationsplein 45 Ruimte A7.209 3013 AM Rotterdam </p>
            <h1 id="4-onvoorziene-cookies">
              <strong>4. Onvoorziene cookies</strong>
            </h1>
            <p>
              Wij proberen een zo goed mogelijk overzicht te houden over de
              cookies op onze Website. Het kan voorkomen dat derden cookies
              plaatsen op onze Website. In dat geval vindt u deze cookies niet
              terug in dit overzicht. Het gaat dan met name om embedded
              elementen. Hier is bijvoorbeeld sprake van als een video die op
              Youtube is opgeslagen op onze Website wordt gedeeld. Komt u op
              onze Website een cookie tegen die niet in dit overzicht staat? Dan
              verzoeken wij u contact op te nemen met ons door een mail te
              sturen naar <a href="mailto:info@closure.nl">info@closure.nl</a>.
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Cookies

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Cookies" location={location}>
      <CookiesSEO />
    </HeadParent>
  )
}
