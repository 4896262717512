import React from "react"

function NabestaandenSEO() {
  return (
    <meta
      name="description"
      content="Nabestaandenloket is te gebruiken door nabestaanden via hun uitvaartorganisatie. Via het Nabestaandenloket platform kan de nabestaande direct aan de slag met het regelen van alle zaken rondom de nazorg."
    />
  )
}

export default NabestaandenSEO
