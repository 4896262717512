import React from "react"

import { ReactComponent as LinkedinLogo } from "../../images/partners/linkedin-icon.svg"

export interface IconProps {
  variant: "linkedin"
}

function Icon({ variant }: IconProps) {
  const iconBase = {
    logo: null,
    link: "",
  }
  switch (variant) {
    case "linkedin":
      iconBase.logo = <LinkedinLogo />
      iconBase.link = "https://www.linkedin.com/company/nabestaandenloket/"
      break
    default:
      iconBase.logo = <LinkedinLogo />
      iconBase.link = "https://www.linkedin.com/company/nabestaandenloket/"
      break
  }
  return (
    <a href={iconBase.link}>
      <div className="flex h-10 w-10 items-center rounded-full bg-purple p-3">
        {iconBase.logo}
      </div>
    </a>
  )
}

export default Icon
