import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const subscriptions = [
  {
    id: 1,
    name: "Netflix",
    img: (
      <StaticImage
        src="../../images/partners/netflix.png"
        alt="netflix logo"
        className=""
      />
    ),
  },
  {
    id: 2,
    name: "Spotify",
    img: (
      <StaticImage
        src="../../images/partners/spotify.png"
        alt="netflix logo"
        className=""
      />
    ),
  },
  {
    id: 3,
    name: "Ziggo",
    img: (
      <StaticImage
        src="../../images/partners/ziggo.png"
        alt="ziggo logo"
        className=""
      />
    ),
  },
  {
    id: 4,
    name: "ABN Amro",
    img: (
      <StaticImage
        src="../../images/partners/abn.png"
        alt="abn logo"
        className=""
      />
    ),
  },
]
const subscriptionsApp = [
  {
    id: 1,
    name: "Spotify",
    img: (
      <StaticImage
        src="../../images/partners/spotify.png"
        alt="netflix logo"
        className=""
      />
    ),
  },
  {
    id: 2,
    name: "ABN Amro",
    img: (
      <StaticImage
        src="../../images/partners/abn.png"
        alt="abn logo"
        className=""
      />
    ),
  },
]

export { subscriptions, subscriptionsApp }
