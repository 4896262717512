import * as messagesNL from './nl';

const LOCALES = {
  DUTCH: 'nl',
};

const MESSAGES = {
  [LOCALES.DUTCH]: messagesNL.default,
};

export { MESSAGES, LOCALES };
