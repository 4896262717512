import { Link } from "gatsby"
import React from "react"
import { FormattedMessage } from "react-intl"

import { navItems } from "../../../content/data/navigation"
import { ReactComponent as LogoLight } from "../../../images/logo/logo-light.svg"
import Icon from "../../Icon"

function Footer() {
  return (
    <div className="bg-sand">
      <div className="container mx-auto max-w-7xl py-16 px-4 lg:py-16 ">
        <div className="flex grid-flow-col flex-col md:grid md:gap-8">
          <div className="grid grid-flow-row grid-cols-2 gap-y-10 gap-x-10 lg:grid-flow-col lg:grid-cols-4">
            {navItems.map(navItemGroup => (
              <div className="pb-2" key={navItemGroup.id}>
                <div className="text-darkblue pb-2 text-lg font-medium">
                  {navItemGroup.placeholder}
                </div>
                {navItemGroup.navItems.map(navItem => (
                  <Link
                    key={navItem.id}
                    to={navItem.url}
                    className="text-plum hover:text-darkblue-200 flex h-10 flex-row items-center text-sm lg:text-base"
                  >
                    {navItem.name}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-28 grid grid-flow-row flex-col items-center sm:grid-flow-col sm:grid-cols-2 md:grid md:gap-8">
          <div className="col-span-1">
            <LogoLight className="mb-2 h-4 w-auto md:h-6" />
            <span className="text-blue text-xs font-normal">
              <FormattedMessage id="copyright" />
            </span>
          </div>
          <div className="col-span-1 mt-10 flex items-center justify-start space-x-4 sm:mt-0 sm:justify-end">
            <Icon variant="linkedin" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
