import React from "react"

function ContactSEO() {
  return (
    <meta
      name="description"
      content="
  Als je vragen hebt over onze dienstverlening kunt u gratis contact opnemen met onze nabestaandendesk. Deze is bereikbaar van maandag t/m vrijdag tussen 08:30 en 21:00, op zaterdag tussen 09:00 en 13:00."
    />
  )
}

export default ContactSEO
